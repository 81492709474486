import React from "react";
import autoBind from "react-autobind";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from "lodash";
import {toast} from "react-toastify";

import TokenCard from "./TokenCard";

const noDisplayStyle = {display: "none"};

const cardStyle = {
  height: `calc(100vh - ${"120px"})`,
  width: `calc(100vh - ${"40px"})`,
  maxWidth: "1023px",
};

const marginLeft = {marginLeft: "16px"};

export default class TokenManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tokens: this.props.tokens,
      uploading: this.props.uploading || false,
    };

    this.inputOpenFileRef = React.createRef();
    autoBind(this);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      this.setState((prev) => ({
        ...prev,
        tokens: this.props.tokens,
        uploading: this.props.uploading || false,
      }));
    }
  }

  showOpenFileDlg() {
    this.inputOpenFileRef.current.click();
  }

  handleTokenFilesChanged(e) {
    const files = e.target.files || null;
    if (files.length > 10) {
      toast.error("Maximum token upload 10 at once!");
    } else {
      this.props.handleFileUpload(e, "token", this.props.sessionId, this.props.userId);
    }
    e.target.value = null;
  }

  deleteToken(e) {
    this.props.handleDeleteToken(e.target.id);
    toast.success("Deleted Token!");
  }

  onTagChange(tagList, id) {
    this.props.setTags(tagList, id);
  }

  render() {
    return (
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card" style={cardStyle}>
          <header className="modal-card-head">
            <div className="modal-card-title">
              Token Manager
              <div className="button is-info is-pulled-right" onClick={this.showOpenFileDlg}>
                <span className="icon">
                  <FontAwesomeIcon icon={Icons.faUpload} />
                </span>
                <span>Upload tokens</span>
              </div>
            </div>

            <button
              className="delete"
              aria-label="delete"
              onClick={this.props.handleCloseTokenManager}
              style={marginLeft}
            />
          </header>
          <input
            ref={this.inputOpenFileRef}
            type="file"
            style={noDisplayStyle}
            onChange={this.handleTokenFilesChanged}
            multiple
            value=""
          />
          <section className="modal-card-body">
            <div className="columns is-multiline">
              {this.state.tokens
                .sort(function (a, b) {
                  return a.id - b.id;
                })
                .map((t, i) => (
                  <TokenCard
                    t={t}
                    i={i}
                    key={`token-${t.id}`}
                    deleteToken={this.deleteToken}
                    onTagChange={this.onTagChange}
                  />
                ))}
              {this.state.uploading && <TokenCard loading />}
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-success" onClick={this.props.handleCloseTokenManager}>
              Done
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
