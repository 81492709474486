import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import autoBind from "react-autobind";
import _ from "lodash";

const flexColStyle = {display: "flex", flexDirection: "column"};
const flexGrowStyle = {overflowY: "auto", flexGrow: "1"};
const flexMarginStyle = {display: "flex", margin: "6px 0 6px 0"};
const marginTop = {marginRight: "8px"};
const flexGrow = {flexGrow: "1"};

class MarkersPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: this.props.gameData.markers
        .filter((m) => m.boardId === this.props.gameData.manageId)
        .sort((a, b) => a.id - b.id),
      selectedMarker: {id: 0, title: "", desc: ""},
    };

    autoBind(this);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      this.setState((prev) => ({
        ...prev,
        markers: this.props.gameData.markers
          .filter((m) => m.boardId === this.props.gameData.manageId)
          .sort((a, b) => a.id - b.id),
      }));
    }
  }

  placeNewMarker() {
    this.props.setMarkerAdding(true);
  }

  clearSelectedMarker() {
    this.setState((prev) => ({
      ...prev,
      selectedMarker: {id: 0, title: "", desc: ""},
    }));
  }

  selectMarker(id) {
    if (this.state.selectedMarker.id === id) {
      this.clearSelectedMarker();
    } else {
      const currentMarker = this.state.markers.find((m) => m.id === id);

      this.setState((prev) => ({
        ...prev,
        selectedMarker: currentMarker,
      }));
    }
  }

  deleteMarker(id) {
    this.props.deleteMarker(id);
    if (this.state.selectedMarker.id === id) this.clearSelectedMarker();
  }

  handleTitleChange(e) {
    const title = e.target.value;
    this.setState((prev) => ({
      ...prev,
      selectedMarker: {
        ...this.state.selectedMarker,
        title: title,
      },
    }));
  }

  handleDescChange(e) {
    const desc = e.target.value;
    this.setState((prev) => ({
      ...prev,
      selectedMarker: {
        ...this.state.selectedMarker,
        desc: desc,
      },
    }));
  }

  saveChanges() {
    this.props.storeMarker(this.state.selectedMarker);
  }

  render() {
    return (
      <div className="content" style={flexColStyle}>
        <div>
          <h2>GM Markers</h2>
          <blockquote className="is-info">
            GM Markers can be used to name and take some notes for places or events on the map. They are only visible
            for you!
            <br />
            <b>Tokens are not shown when this menu pane is active!</b>
          </blockquote>
          <div className="buttons">
            <button className="button is-info" onClick={this.placeNewMarker}>
              Add marker
            </button>
          </div>
          {this.state.markers.length > 0 && <h4>Current markers</h4>}
        </div>
        <div style={flexGrowStyle}>
          {this.state.markers.map((m) => (
            <div
              key={`m-${m.id}`}
              className={m.id === this.state.selectedMarker.id ? "selected-marker" : "hover-blue"}
              style={flexMarginStyle}
            >
              <div style={marginTop}>
                <span className="icon">
                  <FontAwesomeIcon icon={Icons.faMapMarkerAlt} />
                </span>
              </div>
              <div style={flexGrow} onClick={() => this.selectMarker(m.id)}>
                {!m.title ? "[NO TITLE]" : m.title}
              </div>
              <div className="hover-red" onClick={() => this.deleteMarker(m.id)}>
                <span className="icon">
                  <FontAwesomeIcon icon={Icons.faTimes} />
                </span>
              </div>
            </div>
          ))}
        </div>
        <br />
        {this.state.selectedMarker.id !== 0 && (
          <>
            <div className="field">
              <label className="label">Title</label>
              <div className="control">
                <input
                  className="input is-info"
                  type="text"
                  placeholder="Enter title"
                  maxLength="31"
                  value={this.state.selectedMarker.title || ""}
                  onBlur={this.saveChanges}
                  onChange={this.handleTitleChange}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Description</label>
              <div className="control">
                <input
                  className="input is-info"
                  type="text"
                  placeholder="Enter description"
                  maxLength="300"
                  value={this.state.selectedMarker.desc || ""}
                  onBlur={this.saveChanges}
                  onChange={this.handleDescChange}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default MarkersPane;
