import React from "react";
import {SOCKET_URI} from "../../constants/uri";

const imgSize = {height: "100px", width: "100px", margin: "2px"};

export default function TokenImage(props) {
  return (
    <img
      style={imgSize}
      src={`${SOCKET_URI}/download/token/${props.token.tokenId}`}
      alt={props.token.id}
      id={props.token.id}
      key={`img-${props.token.id}`}
      border={props.selected ? "6px solid #000" : ""}
      onClick={props.handleTokenClick}
    />
  );
}
