export default function fogPolygon(ctxMask, coords, isGameMaster) {
  if (isGameMaster) ctxMask.globalAlpha = 0.7;

  ctxMask.strokeStyle = "#df4b26";
  ctxMask.lineWidth = 5;

  ctxMask.beginPath();
  ctxMask.moveTo(coords[0].x, coords[0].y);
  for (var i = 1; i < coords.length; i++) {
    ctxMask.lineTo(coords[i].x, coords[i].y);
  }
  ctxMask.closePath();
  ctxMask.fill();

  ctxMask.globalAlpha = 1;
  if (isGameMaster) ctxMask.stroke();
}
