import React from "react";
import autoBind from "react-autobind";
import {withRouter} from "react-router-dom";
import _ from "lodash";
const sectionStyle = {backgroundColor: "darkgray"};
const cardStyle = {minWidth: "600px", padding: "16px", borderRadius: "6px", opacity: "0.9"};

class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: _.get(props, "location.state.title", null) || _.get(props, "state.title", null) || "Error",
      content:
        _.get(props, "location.state.content", null) ||
        _.get(props, "state.content", null) ||
        "An unknown error occurred.",
    };
    autoBind(this);
  }

  render() {
    return (
      <>
        <section className="hero is-fullheight" style={sectionStyle}>
          <div className="hero-body">
            <div className="container">
              <div className="columns is-centered">
                <div className="card" style={cardStyle}>
                  <div className="card-content">
                    <h1 className="title">{this.state.title}</h1>

                    <div className="content">
                      <h4 className="title is-4">{this.state.content}</h4>
                    </div>
                    <div className="control">
                      <button
                        className="button is-info"
                        onClick={this.props.history.push({
                          pathname: "/",
                        })}
                      >
                        Go to Homepage
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(ErrorPage);
