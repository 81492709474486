import React from "react";
import Draggable from "react-draggable";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import autoBind from "react-autobind";

const draggableStyle = {
  position: "absolute",
  zIndex: "1",
  width: "300px",
  left: "32px",
  top: window.innerHeight - 400,
};

const textBackgroundStyle = {backgroundColor: "whitesmoke"};

export default class ChatBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chatMessage: "",
      chatText: "",
    };

    this.chatRef = React.createRef();

    autoBind(this);
  }

  componentDidUpdate() {
    this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
  }

  handleChatTextChange(e) {
    const input = e.target.value || "";
    this.setState((prev) => ({chatMessage: input}));
  }

  handleKeyPress(e) {
    if (e.keyCode === 13 && this.state.chatMessage) {
      this.props.sendChatMessage(this.state.chatMessage);
      this.setState((prev) => ({chatMessage: ""}));
    }
  }

  handleButtonClick() {
    if (this.state.chatMessage) {
      this.props.sendChatMessage(this.state.chatMessage);
      this.setState((prev) => ({chatMessage: ""}));
    }
  }

  renderChatData() {
    let chatText = "";
    if (this.props.chatData) {
      this.props.chatData.map((chatItem) => (chatText += `${chatItem.time} ${chatItem.user}: ${chatItem.message}\n`));
    }
    return chatText;
  }

  render() {
    return (
      <>
        <Draggable handle=".handle">
          <div className="div transparent" style={draggableStyle}>
            <div className="handle button is-info is-fullwidth hover-menu-title">
              <span className="icon">
                <FontAwesomeIcon icon={Icons.faArrowsAlt} />
              </span>
              <span>Group Chat</span>
              <span className="delete" onClick={this.props.handleToggleChatWindowVisibility}></span>
            </div>
            <textarea
              className="textarea transparent"
              value={this.renderChatData()}
              rows="10"
              readOnly
              onChange={this.scrollToBottom}
              ref={this.chatRef}
              style={textBackgroundStyle}
            />
            <div className="field is-grouped transparent">
              <p className="control is-expanded">
                <input
                  className="input"
                  type="text"
                  placeholder="Type something"
                  onChange={this.handleChatTextChange}
                  onKeyDown={this.handleKeyPress}
                  value={this.state.chatMessage}
                />
              </p>
              <div className="control">
                <div className="button is-info" onClick={this.handleButtonClick}>
                  Send
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      </>
    );
  }
}
