import {Circle, Group, Label, Rect, RegularPolygon, Tag, Text} from "react-konva";

import React, {Fragment} from "react";
import _ from "lodash";
import autoBind from "react-autobind";

export default class MountedToken extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: this.props.token,
      img: null,
    };
    autoBind(this);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      this.setState((prev) => ({...prev, token: this.props.token}), this.loadImage);
    }
  }

  componentDidMount() {
    this.loadImage();
  }

  loadImage() {
    let img = new Image();
    img.src = this.props.token.image;
    img.onerror = () => {
      this.props.handleImageError(img.src);
    };
    img.onload = () => {
      this.setState((prev) => ({
        ...prev,
        img: img,
      }));
    };
  }

  getXOffset(caption, fontSize) {
    const tag = document.createElement("div");
    tag.style.position = "absolute";
    tag.style.left = "-999em";
    tag.style.whiteSpace = "nowrap";
    tag.style.font = `${fontSize}px Arial`;
    tag.innerHTML = caption;

    document.body.appendChild(tag);
    const result = tag.clientWidth;
    document.body.removeChild(tag);

    return -result / 2 - this.props.gridSize / 20;
  }

  render() {
    const {token, img} = this.state;
    const zoomFactor = this.props.grid === "hex" ? 0.85 : 1;
    const commonProps = {
      id: token.id,
      fillPatternImage: img,
      fillPatternScaleY: img ? (this.props.gridSize / img.height) * zoomFactor : 1,
      fillPatternScaleX: img ? (this.props.gridSize / img.width) * zoomFactor : 1,
      stroke: "black",
      strokeWidth: 5,
    };

    return (
      <Fragment key={`mt-${token.id}`}>
        {this.state.token !== null && (
          <Group
            id={token.id}
            onDragEnd={this.props.handleTokenDragEnd}
            onclick={this.props.handleTokenClick}
            onMouseDown={this.props.handleMouseDown}
            onMouseUp={this.props.handleMouseUp}
            draggable={this.props.draggable}
            x={token.positionX}
            y={token.positionY}
          >
            {this.props.grid === "nogrid" && (
              <>
                {this.props.glow && (
                  <Circle id={token.id} radius={(this.props.gridSize / 2 - 2) * 1.3} fill={"orange"} opacity={0.8} />
                )}
                <Circle
                  {...commonProps}
                  radius={this.props.gridSize / 2 - 2}
                  fillPatternOffsetX={img ? img.width / 2 : 0}
                  fillPatternOffsetY={img ? img.height / 2 : 0}
                />
              </>
            )}
            {this.props.grid === "rect" && (
              <>
                {this.props.glow && (
                  <Rect
                    id={token.id}
                    width={(this.props.gridSize - 4) * 1.3}
                    height={(this.props.gridSize - 4) * 1.3}
                    offsetX={(this.props.gridSize / 2) * 1.3}
                    offsetY={(this.props.gridSize / 2) * 1.3}
                    fill={"orange"}
                    opacity={0.8}
                  />
                )}
                <Rect
                  {...commonProps}
                  width={this.props.gridSize - 4}
                  height={this.props.gridSize - 4}
                  offsetX={this.props.gridSize / 2}
                  offsetY={this.props.gridSize / 2}
                />
              </>
            )}
            {this.props.grid === "hex" && (
              <RegularPolygon
                {...commonProps}
                sides={6}
                radius={this.props.gridSize / 2 - 2}
                fillPatternOffsetX={this.state.img ? this.state.img.width / 2 : 0}
                fillPatternOffsetY={this.state.img ? this.state.img.height / 2 : 0}
              />
            )}
            <Label
              id={token.id}
              x={this.getXOffset(token.caption, (this.props.gridSize * token.multi) / 4)}
              y={this.props.gridSize / 2}
            >
              <Tag id={token.id} fill="white" opacity={0.7} cornerRadius={this.props.gridSize / 20} />
              <Text
                id={token.id}
                text={token.caption}
                fontSize={(this.props.gridSize * token.multi) / 4}
                padding={this.props.gridSize / 20}
              />
            </Label>
          </Group>
        )}
      </Fragment>
    );
  }
}
