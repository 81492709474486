import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {Stage} from "react-konva";
import {Layer} from "react-konva";
import BoardThumbnail from "./../BoardThumbnail";
import LoadingBoardThumbnail from "./../LoadingBoardThumbnail";
import autoBind from "react-autobind";

const noDisplayStyle = {display: "none"};
const centerStyle = {display: "grid", placeItems: "center"};

class BoardPane extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.uploadBoard = React.createRef();

    autoBind(this);
  }

  showUploadBoard() {
    this.uploadBoard.current.click();
  }

  handleBoardFilesChanged(e) {
    this.props.handleFileUpload(e, "board", this.props.sessionId);
    this.uploadBoard.current.value = null;
    e.target.value = null;
  }

  render() {
    const fieldStyle = {
      overflowY: "scroll",
      overflowX: "hidden",
      maxHeight: Math.round(window.innerHeight * 0.7),
    };

    return (
      <>
        <div className="field">
          <button className="button is-outlined" onClick={this.showUploadBoard}>
            <span className="icon">
              <i className="fas fa-upload" />
              <FontAwesomeIcon icon={Icons.faUpload} />
            </span>
            <span>Upload board file (JPG/PNG)</span>
          </button>
        </div>
        <input
          ref={this.uploadBoard}
          type="file"
          style={noDisplayStyle}
          onChange={this.handleBoardFilesChanged}
          value=""
        />
        <label>Available boards:</label>
        <br />
        <div className="field" style={fieldStyle}>
          <Stage width={295} height={this.props.boards.length * 125}>
            <Layer>
              {this.props.boards
                .sort((a, b) => a.boardId - b.boardId)
                .map((board, i) => (
                  <BoardThumbnail
                    i={i}
                    key={`board-thumb-${board.boardId}`}
                    board={board}
                    x={10}
                    y={10 + i * 125}
                    selectBoard={this.props.selectBoard}
                    manageBoard={this.props.manageBoard}
                    deleteBoard={this.props.deleteBoard}
                    selected={this.props.boardId === board.boardId}
                    managed={this.props.manageId === board.boardId}
                  />
                ))}
            </Layer>
          </Stage>
        </div>
        {this.props.uploading && (
          <div style={centerStyle}>
            <LoadingBoardThumbnail />
          </div>
        )}
      </>
    );
  }
}

export default BoardPane;
