import React from "react";
import _ from "lodash";
import autoBind from "react-autobind";

import {Layer} from "react-konva";
import Marker from "./Marker";

class MarkerLayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      markers: this.props.markers.filter((m) => m.boardId === this.props.gameData.manageId).sort((a, b) => a.id - b.id),
      isMarkerMenuOpen: this.props.isMarkerMenuOpen,
    };
    this.markerLayerRef = React.createRef();
    autoBind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.props.handleBoardClick);
    this.markerLayerRef.current.getCanvas()._canvas.id = "markers";
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.props.handleBoardClick);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      this.setState((prev) => ({
        ...prev,
        markers: this.props.markers
          .filter((m) => m.boardId === this.props.gameData.manageId)
          .sort((a, b) => a.id - b.id),
        isMarkerMenuOpen: this.props.isMarkerMenuOpen,
      }));
    }
  }

  tokenMouseDown(e) {
    this.markerLayerRef.current.canvas._canvas.classList.add("tokenlayer");
  }

  tokenMouseUp(e) {
    this.markerLayerRef.current.canvas._canvas.classList.remove("tokenlayer");
  }

  render() {
    const absoluteStyle = {
      position: "absolute",
    };

    return (
      <React.Fragment>
        <Layer ref={this.markerLayerRef} style={absoluteStyle}>
          {this.state.markers.map((m, i) => (
            <Marker
              marker={m}
              i={i}
              key={`marker-${m.id}`}
              handleMouseDown={this.tokenMouseDown}
              handleMouseUp={this.tokenMouseUp}
              draggable={this.state.isMarkerMenuOpen}
              gridSize={this.props.gameData.gridSize}
              moveMarker={this.props.moveMarker}
              boardWidth={this.props.gameData.boardWidth}
              boardHeight={this.props.gameData.boardHeight}
            />
          ))}
        </Layer>
      </React.Fragment>
    );
  }
}

export default MarkerLayer;
