import { UserManager } from "oidc-client";

const { protocol, hostname, port } = window.location;
const rootUri = `${protocol}//${hostname}${port ? `:${port}` : ""}`;

const settings = {
  authority: "https://campaign-identity.com",
  client_id: "ctt.ui",
  redirect_uri: `${rootUri}/oidc-callback`,
  post_logout_redirect_uri: `${rootUri}/oidc-callback`,
  automaticSilentRenew: true,
  silent_redirect_uri: `${rootUri}/silent_renew.html`,
  loadUserInfo: true,
  response_type: "token id_token",
  scope: "openid profile cid.reg",
};

const userManager = new UserManager(settings);

export default userManager;
