import * as Icons from "@fortawesome/free-solid-svg-icons";

import Draggable from "react-draggable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import autoBind from "react-autobind";
import {SOCKET_URI} from "../../constants/uri";

const divStyle = {
  position: "absolute",
  zIndex: "1",
  width: "300px",
  left: "32px",
  top: "92px",
  backgroundColor: "whitesmoke",
  borderRadius: "6px",
};

const justifyContent = {
  justifyContent: "space-between",
};

export default class UserList extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    return (
      <>
        <Draggable handle=".handle">
          <div className="div transparent" style={divStyle}>
            <div className="handle button is-info is-fullwidth hover-menu-title">
              <span className="icon">
                <FontAwesomeIcon icon={Icons.faArrowsAlt} />
              </span>
              <span>User List</span>
              <span className="delete" onClick={this.props.handleToggleUserListVisibility}></span>
            </div>
            <article className="panel is-info transparent">
              {this.props.session.users &&
                this.props.session.users
                  .filter((u) => u.isLoggedIn)
                  .sort()
                  .map((user, i) => {
                    const uId = user.userId.toString();
                    const uName = user.userName.toString();

                    return (
                      <div
                        className={uId === this.props.userId ? "panel-block is-active" : "panel-block"}
                        key={`user-${this.props.userId}`}
                        style={justifyContent}
                      >
                        <div>
                          <span className="panel-icon">
                            <FontAwesomeIcon
                              icon={this.props.session.gameMaster === uId ? Icons.faCrown : Icons.faUser}
                            />
                          </span>
                          {uName}
                        </div>
                        {this.props.session.gameMaster !== uId && user.avatar !== null && (
                          <img src={`${SOCKET_URI}/download/token/${user.avatar}`} alt="" width="40" height="40" />
                        )}
                      </div>
                    );
                  })}
            </article>
          </div>
        </Draggable>
      </>
    );
  }
}
