import React, {Component} from "react";
import autoBind from "react-autobind";

class FowPane extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    autoBind(this);
  }

  deleteFow() {
    if (
      window.confirm(
        "Are you sure you want to delete all Fog of War data for this board and reveal the map to the players?"
      )
    ) {
      this.props.deleteFow();
    }
  }

  deleteAllPolygons() {
    this.props.deleteAllPolygons();
  }

  deleteLastPolygon() {
    this.props.deleteLastPolygon();
  }

  changeToFreeMode() {
    this.props.changeFowDrawMode("free");
  }

  changeToFogMode() {
    this.props.changeFowDrawMode("fog");
  }

  enableFow() {
    this.props.setFow(true);
  }

  disableFow() {
    this.props.setFow(false);
  }

  render() {
    return (
      <div className="content">
        <h2>Fog of War</h2>
        <blockquote className="is-info">
          Fog of War is an area that is hidden from players. When enabled it will at first be
          enabled for the whole map. With the polygon tool areas can be revealed or hidden again to
          the players.
          <br />
          <b>When this menu pane is selected the tokens on the board cannot be controlled!</b>
        </blockquote>
        <h4>Enable Fog of War feature for this board</h4>
        <div className="buttons has-addons">
          <button
            className={this.props.fowEnabled ? "button is-info" : "button"}
            onClick={this.enableFow}
          >
            Turn ON
          </button>
          <button
            className={!this.props.fowEnabled ? "button is-info" : "button"}
            onClick={this.disableFow}
          >
            Turn OFF
          </button>
        </div>
        <h5 className="is-flex">
          Current status: &nbsp;
          {this.props.fowEnabled ? (
            <p style={{color: "green"}}>FOW is enabled</p>
          ) : (
            <p style={{color: "red"}}>FOW is disabled</p>
          )}
        </h5>
        {this.props.fowEnabled && (
          <>
            <h3>The Polygon tool</h3>
            <p>
              You have to hold the <b>ALT-KEY</b> down when adding points by clicking onto the
              board. Once you clicked on the starting point to close the path, the polygon will be
              drawn on to the board. If you wish to abort the current sketch, press the{" "}
              <b>ESC-Key</b>
            </p>

            <h5>Select drawing mode</h5>

            <div className="buttons has-addons">
              <button
                className={this.props.fowMode === "free" ? "button is-info" : "button"}
                onClick={this.changeToFreeMode}
              >
                FREE
              </button>
              <button
                className={this.props.fowMode === "fog" ? "button is-info" : "button"}
                onClick={this.changeToFogMode}
              >
                FOG
              </button>
            </div>
            <div className="buttons is-grouped">
              <button className="button is-info" onClick={this.deleteLastPolygon}>
                Undo last drawing
              </button>
              <button className="button is-warning" onClick={this.deleteAllPolygons}>
                Remove all polygons
              </button>
            </div>

            <h4>Delete all FOW data and reveal the whole map to the players?</h4>
            <div className="control">
              <button className="button is-danger" onClick={this.deleteFow}>
                Delete FOW mask
              </button>
            </div>
            <br />
          </>
        )}
      </div>
    );
  }
}

export default FowPane;
