import React from "react";
import autoBind from "react-autobind";
import {licenses} from "../static/licenses.js";
const sectionStyle = {backgroundColor: "#cce1f3"};

export default class Sources extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  downloadLicenses() {
    var fileContent = licenses;
    var fileName = "licenses.txt";

    const blob = new Blob([fileContent], {type: "text/plain"});
    const a = document.createElement("a");
    a.setAttribute("download", fileName);
    a.setAttribute("href", window.URL.createObjectURL(blob));
    a.click();
  }

  render() {
    return (
      <>
        <section className="section" style={sectionStyle}>
          <div className="container">
            <h1 className="title">Image Sources:</h1>

            <div className="content">
              <label className="label">General:</label>

              <ul>
                <li>
                  Login Page Image and cropped Fav Icon by &nbsp;
                  <a href="https://unsplash.com/@youngprodigy3?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
                    Mike Szczepanski
                  </a>
                  &nbsp; on&nbsp;
                  <a href="https://unsplash.com/s/photos/game-dice?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
                    Unsplash
                  </a>
                </li>
                <li>
                  Session Page by &nbsp;
                  <a href="https://unsplash.com/@jovka?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
                    Jowita Jeleńska
                  </a>
                  &nbsp; on &nbsp;
                  <a href="https://unsplash.com/s/photos/game-dice?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
                    Unsplash
                  </a>
                </li>
                <li>
                  Game Board from &nbsp;
                  <a href="https://dysonlogos.blog/2020/11/07/the-borderlands-caves-level-1/">dysonlogos.blog</a>
                </li>
                <li>
                  Board selection eye icon by &nbsp;
                  <a href="https://www.flaticon.com/de/autoren/freepik" title="Freepik">
                    Freepik
                  </a>
                  &nbsp; from &nbsp;
                  <a href="https://www.flaticon.com/de/" title="Flaticon">
                    www.flaticon.com
                  </a>
                </li>
              </ul>

              <label className="label">Icons from grid pane:</label>
              <ul>
                <li>
                  <a href="https://thenounproject.com/search/?q=plus&i=4334124" title="TheNounProject">
                    Plus By Handicon, ID In the Math Symbol - Outline Collection
                  </a>
                </li>
                <li>
                  <a href="https://thenounproject.com/term/minus/4334114/" title="TheNounProject">
                    Minus By Handicon, ID In the Math Symbol - Outline Collection
                  </a>
                </li>
                <li>
                  <a href="https://thenounproject.com/term/close/19368/" title="TheNounProject">
                    Close By Gustavo Cordeiro, AE
                  </a>
                </li>
                <li>
                  <a href="https://thenounproject.com/vecco/collection/shape-i/?i=2901688" title="TheNounProject">
                    Hexagon By vecco In the Shape I Collection
                  </a>
                </li>
                <li>
                  <a href="https://thenounproject.com/term/square/2901687/" title="TheNounProject">
                    square By vecco In the Shape I Collection
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <br />
          <div className="container">
            <h1 className="title">Licenses:</h1>
            <button className="button is-info" onClick={this.downloadLicenses}>
              Download Licenses as Text File
            </button>
          </div>
        </section>
      </>
    );
  }
}
