import React, {Component} from "react";
import autoBind from "react-autobind";
import {SketchPicker} from "react-color";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from "lodash";

import hexImage from "../../../static/images/hexagon.png";
import rectImage from "../../../static/images/rect.png";
import nogridImage from "../../../static/images/nogrid.png";
import plusImage from "../../../static/images/plus.png";
import minusImage from "../../../static/images/minus.png";

const flexColStyle = {display: "flex", flexDirection: "column"};
const fullWidth = {
  width: "100%",
};

const multiLabel = {
  fontWeight: "bold",
  padding: "4px",
  width: "40px",
};

const inputStyle = {width: "72px", marginLeft: "8px", marginRight: "8px", textAlign: "center"};
const relativeStyle = {position: "relative", marginLeft: "32px"};

const columnStyle = {paddingTop: 0, paddingBottom: 0};

const gridOffsetRightStyle = {
  width: "36px",
  marginLeft: "8px",
  marginRight: "2px",
  textAlign: "center",
  padding: "0 0 0 0",
};

const gridOffsetLeftStyle = {
  width: "36px",
  marginLeft: "2px",
  marginRight: "8px",
  textAlign: "center",
  padding: "0 0 0 0",
};
const popover = {
  position: "absolute",
  zIndex: "2",
  top: "-306px",
};

class GridPane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridSize: this.props.gridSize,
      gridLineWidth: this.props.gridLineWidth,
      gridLineColor: this.props.gridLineColor,
      gridOffsetX: this.props.gridOffsetX,
      gridOffsetY: this.props.gridOffsetY,
    };

    autoBind(this);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      this.setState((prev) => ({
        ...prev,
        gridSize: this.props.gridSize,
        gridLineWidth: this.props.gridLineWidth,
        gridLineColor: this.props.gridLineColor,
        gridOffsetX: this.props.gridOffsetX,
        gridOffsetY: this.props.gridOffsetY,
      }));
    }
  }

  changeGridToRect() {
    this.handleGridButtonClick("rect", this.props.gridSize);
  }

  changeGridToHex() {
    this.handleGridButtonClick("hex", this.props.gridSize);
  }

  changeGridToNoGrid() {
    this.handleGridButtonClick("nogrid", this.props.gridSize);
  }

  handleGridButtonClick(grid) {
    this.props.changeGrid(grid);
  }

  handleGridSizeChange(e) {
    const gridSize = e.target.value || 100;
    this.setState((prev) => ({
      ...prev,
      gridSize: gridSize,
    }));
  }

  handleChangeGridSize() {
    if (this.state.gridSize !== null) this.props.setGridSize(this.state.gridSize);
  }

  changeGridWidth(width) {
    this.props.setGridWidth(width);
  }

  handleChangeGridLineColor(color) {
    this.setState((prev) => ({
      ...prev,
      gridLineColor: color.hex,
    }));
    this.props.setGridLineColor(color.hex);
  }

  handleGridOffsetUp() {
    let gridOffsetY = this.props.gridOffsetY || this.state.gridOffsetY;
    gridOffsetY -= 1;

    this.props.setGridOffset(this.props.gridOffsetX, gridOffsetY);
  }

  handleGridOffsetDown() {
    let gridOffsetY = this.props.gridOffsetY || this.state.gridOffsetY;
    gridOffsetY += 1;

    this.props.setGridOffset(this.props.gridOffsetX, gridOffsetY);
  }

  handleGridOffsetLeft() {
    let gridOffsetX = this.props.gridOffsetX || this.state.gridOffsetX;
    gridOffsetX -= 1;

    this.props.setGridOffset(gridOffsetX, this.props.gridOffsetY);
  }

  handleGridOffsetRight() {
    let gridOffsetX = this.props.gridOffsetX || this.state.gridOffsetX;
    gridOffsetX += 1;

    this.props.setGridOffset(gridOffsetX, this.props.gridOffsetY);
  }

  handleGridOffsetXChange(e) {
    const offset = e.target.value || 0;

    this.setState((prev) => ({
      ...prev,
      gridOffsetX: offset,
    }));

    this.props.setGridOffset(offset, this.state.gridOffsetY);
  }

  handleGridOffsetYChange(e) {
    const offset = e.target.value || 0;

    this.setState((prev) => ({
      ...prev,
      gridOffsetY: offset,
    }));

    this.props.setGridOffset(this.state.gridOffsetX, offset);
  }

  toggleShowGridColorPicker() {
    this.setState((prev) => ({
      ...prev,
      showGridColorPicker: !this.state.showGridColorPicker,
    }));
  }

  onMouseDown(e) {
    const x = e.clientX;
    const y = e.clientY;
    this.setState((prev) => ({
      ...prev,
      startX: x,
      startY: y,
      startOffsetX: this.state.gridOffsetX,
      startOffsetY: this.state.gridOffsetY,
    }));
    document.addEventListener("mousemove", this.onMouseMove);
  }

  onMouseUp(e) {
    document.removeEventListener("mousemove", this.onMouseMove);
    const disX = this.state.startX - e.clientX;
    const disY = this.state.startY - e.clientY;
    this.props.setGridOffset(-disX + this.state.startOffsetX, -disY + this.state.startOffsetY);
  }

  onMouseMove(e) {
    this.moveGrid(e);
  }

  moveGrid(e) {
    const disX = this.state.startX - e.clientX;
    const disY = this.state.startY - e.clientY;
    this.props.previewGridOffset(-disX + this.state.startOffsetX, -disY + this.state.startOffsetY);
  }

  render() {
    const colorPickerStyle = {backgroundColor: this.props.gridLineColor, width: "40px", marginLeft: "16px"};
    const rectStyle = {backgroundColor: this.props.grid === "rect" ? "#cce1f3" : "", borderRadius: "8px"};
    const hexStyle = {backgroundColor: this.props.grid === "hex" ? "#cce1f3" : "", borderRadius: "8px"};
    const nogridStyle = {backgroundColor: this.props.grid === "nogrid" ? "#cce1f3" : "", borderRadius: "8px"};

    return (
      <div className="content" style={flexColStyle}>
        <div>
          <h2>Grid Settings</h2>
          <h5>Choose your gridstyle</h5>
          <div className="flex-row m-4">
            <div className="grid-img-style" onClick={this.changeGridToRect}>
              <img src={rectImage} alt="Rectangular grid" width="128" height="128" style={rectStyle}></img>
              <label className="label">Square grid</label>
            </div>
            <div className="grid-img-style" onClick={this.changeGridToHex}>
              <img src={hexImage} alt="Hexagon grid" width="128" height="128" style={hexStyle}></img>
              <label className="label">Hexagon grid</label>
            </div>
            <div className="grid-img-style" onClick={this.changeGridToNoGrid}>
              <img src={nogridImage} alt="No grid" width="128" height="128" style={nogridStyle}></img>
              <label className="label">No grid</label>
            </div>
          </div>
          <br />
          <h5>Change Grid Size & Color</h5>
          <div className="flex-row">
            <div className="field is-grouped mb-0">
              <button className="button button-paddings-equal" onClick={this.props.decrementGridSize}>
                <img src={minusImage} alt="minus" width="32" height="32" />
              </button>

              <input
                className="input"
                style={inputStyle}
                type="text"
                placeholder="Text input"
                value={this.state.gridSize || this.props.gridSize}
                onChange={this.handleGridSizeChange}
                onBlur={this.handleChangeGridSize}
              />

              <button className="button button-paddings-equal" onClick={this.props.incrementGridSize}>
                <img src={plusImage} alt="minus" width="32" height="32" />
              </button>
            </div>
            <div className="flex-grouped" style={relativeStyle}>
              <label className="label">Choose color:</label>
              <button
                style={colorPickerStyle}
                type="button"
                className="button"
                onClick={this.toggleShowGridColorPicker}
              />
              {this.state.showGridColorPicker && (
                <div style={popover}>
                  <SketchPicker
                    color={this.props.gridLineColor || this.state.gridLineColor}
                    onChangeComplete={this.handleChangeGridLineColor}
                  />
                </div>
              )}
            </div>
          </div>
          <br />
          <h5>Change Grid Thickness</h5>
          <div className="flex-grouped">
            <label className="label">Current width:</label>

            <label className="label" style={multiLabel}>
              {this.props.gridLineWidth}
            </label>

            <div style={{flexGrow: 1}}>
              <input
                style={fullWidth}
                className="slider"
                step="0.5"
                min="0.5"
                max="10"
                value={this.props.gridLineWidth}
                type="range"
                onChange={(e) => this.changeGridWidth(e.target.value)}
              />
            </div>
          </div>
          <br />
          <h5>Adjust Grid Offset</h5>

          <div className="columns p-16">
            <div className="column is-6">
              <div className="columns">
                <div className="column has-text-centered">
                  <button className="button" onClick={this.handleGridOffsetUp}>
                    <FontAwesomeIcon icon={Icons.faArrowUp} />
                  </button>
                </div>
              </div>
              <div className="columns">
                <div className="column has-text-centered " style={columnStyle}>
                  <button className="button" onClick={this.handleGridOffsetLeft}>
                    <FontAwesomeIcon icon={Icons.faArrowLeft} />
                  </button>

                  <input
                    className="input"
                    style={gridOffsetRightStyle}
                    type="text"
                    value={this.props.gridOffsetX}
                    onChange={this.handleGridOffsetXChange}
                  />
                  <input
                    className="input"
                    style={gridOffsetLeftStyle}
                    type="text"
                    value={this.props.gridOffsetY}
                    onChange={this.handleGridOffsetYChange}
                  />

                  <button className="button" onClick={this.handleGridOffsetRight}>
                    <FontAwesomeIcon icon={Icons.faArrowRight} />
                  </button>
                </div>
              </div>
              <div className="columns">
                <div className="column has-text-centered">
                  <button className="button" onClick={this.handleGridOffsetDown}>
                    <FontAwesomeIcon icon={Icons.faArrowDown} />
                  </button>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="flex-center">
                <div className="grid-pane-mover" onMouseDown={this.onMouseDown} onMouseUp={this.onMouseUp}>
                  <div className="label">Hold mouse button pressed and move mouse to move grid</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GridPane;
