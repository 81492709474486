import PropTypes from "prop-types";
import React from "react";
import autoBind from "react-autobind";
import { withRouter } from "react-router-dom";
import NavBar from "./../components/UI/NavBar";

const cardStyle = {
  minWidth: "600px",
  padding: "16px",
  borderRadius: "6px",
  opacity: "0.9",
};

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: null,
      userName: null,
    };

    autoBind(this);
  }

  login() {
    this.props.userManager.signinRedirect();
  }

  openCID() {
    window.open("https://campaign-identity.com/", "_blank");
  }

  render() {
    return (
      <>
        <NavBar />
        <section className="hero bg-img-login is-fullheight">
          <div className="hero-body">
            <div className="container">
              <div className="columns is-centered">
                <div className="card" style={cardStyle}>
                  <div className="card-content">
                    <h2 className="title is-2">Welcome to CTT</h2>
                    <h4 className="subtitle is-4">
                      An easy to use Virtual Table Top Game
                    </h4>
                    <br />
                    <h5 className="title is-5">
                      Try it out and start your adventure right now!
                    </h5>
                    <div className="content">
                      <button
                        className="button is-success is-large is-fullwidth"
                        onClick={this.login}
                      >
                        Login with Campaign ID
                      </button>
                      <br />
                      <h6 className="title is-6">
                        You have no Campaign ID Account? No problem, just
                        register below.
                      </h6>
                      <button
                        className="button is-info is-large is-fullwidth"
                        onClick={this.openCID}
                      >
                        Register
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

LoginPage.propTypes = {
  userManager: PropTypes.object,
};

export default withRouter(LoginPage);
