import React from "react";
import Spinner from "react-spinner-material";
import {SOCKET_URI} from "./../../../constants/uri";
import TagsInput from "./TagsInput";

const imgSize = {height: "100px", width: "100px"};
const spinnerStyle = {width: "100px", height: "100px", margin: "16px"};

function TokenCard(props) {
  const cardStyle = {
    padding: "8px",
    borderRadius: "6px",
    display: props.loading ? "flex" : "",
    justifyContent: props.loading ? "center" : "",
  };
  
  return (
    <div className="column is-4">
      <div className="card" style={cardStyle}>
        {!props.loading && (
          <>
            <img
              style={imgSize}
              src={`${SOCKET_URI}/download/token/${props.t.id}`}
              alt={props.t.id}
              id={props.t.id}
              key={`img-${props.i}`}
            />
            <TagsInput id={props.t.id} value={props.t.tags} onTagChange={props.onTagChange} />
            <footer className="card-footer">
              {props.t.type !== "avatar" && (
                <>
                  <button
                    className="button is-small is-danger is-pulled-right"
                    aria-label="delete"
                    id={props.t.id}
                    key={`but-${props.t.id}`}
                    onClick={props.deleteToken}
                  >
                    Delete
                  </button>
                </>
              )}
            </footer>
          </>
        )}
        {props.loading && (
          <div style={spinnerStyle}>
            <Spinner radius={90} color="#00f" stroke={10} visible={true} />
          </div>
        )}
      </div>
    </div>
  );
}

export default TokenCard;
