import * as Icons from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import autoBind from "react-autobind";

import BoardPane from "./AdminMenu/BoardPane";
import FowPane from "./AdminMenu/FowPane";
import GridPane from "./AdminMenu/GridPane";
import MarkersPane from "./AdminMenu/MarkersPane";
import TokensPane from "./AdminMenu/TokensPane";

const fullWidth = {
  width: "100%",
};

const flexGrow = {
  flexGrow: 1,
};

export default class AdminMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: "board",
    };

    autoBind(this);
  }

  selectTab(tab) {
    this.setState((prev) => ({tab: tab}));
  }

  selectTabBoard() {
    this.selectTab("board");
    this.props.setFowMode(false);
    this.props.setMarkerMenu(false);
  }

  selectTabGrid() {
    this.selectTab("grid");
    this.props.setFowMode(false);
    this.props.setMarkerMenu(false);
  }

  selectTabTokens() {
    this.selectTab("tokens");
    this.props.setFowMode(false);
    this.props.setMarkerMenu(false);
  }

  selectTabFow() {
    this.selectTab("fow");
    this.props.setFowMode(true);
    this.props.setMarkerMenu(false);
  }

  selectTabmarkers() {
    this.selectTab("markers");
    this.props.setFowMode(false);
    this.props.setMarkerMenu(true);
  }

  render() {
    const transparentStyle = {padding: "16px", maxHeight: window.innerHeight - 200};

    return (
      <>
        <div>
          <div className="field has-addons">
            <p className="control" style={flexGrow}>
              <button
                className={this.state.tab === "board" ? "button is-info is-selected" : "button"}
                onClick={this.selectTabBoard}
                style={fullWidth}
              >
                <span className="icon is-small">
                  <FontAwesomeIcon icon={Icons.faGlobe} />
                </span>
                <span>Board</span>
              </button>
            </p>
            <p className="control" style={flexGrow}>
              <button
                className={this.state.tab === "grid" ? "button is-info is-selected" : "button"}
                onClick={this.selectTabGrid}
                style={fullWidth}
              >
                <span className="icon is-small">
                  <FontAwesomeIcon icon={Icons.faTh} />
                </span>
                <span>Grid</span>
              </button>
            </p>
            <p className="control" style={flexGrow}>
              <button
                className={this.state.tab === "tokens" ? "button is-info is-selected" : "button"}
                onClick={this.selectTabTokens}
                style={fullWidth}
              >
                <span className="icon is-small">
                  <FontAwesomeIcon icon={Icons.faCircleNotch} />
                </span>
                <span>Tokens</span>
              </button>
            </p>
            <p className="control" style={flexGrow}>
              <button
                className={this.state.tab === "fow" ? "button is-info is-selected" : "button"}
                onClick={this.selectTabFow}
                style={fullWidth}
              >
                <span className="icon is-small">
                  <FontAwesomeIcon icon={Icons.faSmog} />
                </span>
                <span>FOW</span>
              </button>
            </p>
            <p className="control" style={flexGrow}>
              <button
                className={this.state.tab === "markers" ? "button is-info is-selected" : "button"}
                onClick={this.selectTabmarkers}
                style={fullWidth}
              >
                <span className="icon is-small">
                  <FontAwesomeIcon icon={Icons.faMapMarkerAlt} />
                </span>
                <span>Markers</span>
              </button>
            </p>
          </div>

          <div className="transparent" style={transparentStyle}>
            {this.state.tab === "board" && (
              <BoardPane
                boards={this.props.boards}
                selectBoard={this.props.selectBoard}
                manageBoard={this.props.manageBoard}
                deleteBoard={this.props.deleteBoard}
                boardId={this.props.boardId}
                manageId={this.props.manageId}
                uploading={this.props.uploading}
                handleFileUpload={this.props.handleFileUpload}
                sessionId={this.props.sessionId}
              />
            )}

            {this.state.tab === "grid" && (
              <GridPane
                gridSize={this.props.gridSize}
                grid={this.props.grid}
                changeGrid={this.props.changeGrid}
                decrementGridSize={this.props.decrementGridSize}
                incrementGridSize={this.props.incrementGridSize}
                gridLineWidth={this.props.gridLineWidth}
                gridLineColor={this.props.gridLineColor}
                gridOffsetX={this.props.gridOffsetX}
                gridOffsetY={this.props.gridOffsetY}
                setGridSize={this.props.setGridSize}
                setGridWidth={this.props.setGridWidth}
                setGridLineColor={this.props.setGridLineColor}
                setGridOffset={this.props.setGridOffset}
                previewGridOffset={this.props.previewGridOffset}
              />
            )}

            {this.state.tab === "tokens" && (
              <TokensPane
                selectedTokens={this.props.selectedTokens}
                hlToken={this.props.hlToken}
                allTokens={this.props.allTokens}
                mountedTokens={this.props.mountedTokens}
                handleDeleteToken={this.props.handleDeleteToken}
                handleCollectToken={this.props.handleCollectToken}
                handleOpenTokenManager={this.props.handleOpenTokenManager}
                handleChangeCaption={this.props.handleChangeCaption}
                handleCopyTokens={this.props.handleCopyTokens}
                handleTokenClick={this.props.handleTokenClick}
                handleTokenMultiChange={this.props.handleTokenMultiChange}
                manageId={this.props.manageId}
                boards={this.props.boards}
              />
            )}

            {this.state.tab === "fow" && (
              <FowPane
                fowEnabled={this.props.fowEnabled}
                deleteFow={this.props.deleteFow}
                deleteAllPolygons={this.props.deleteAllPolygons}
                deleteLastPolygon={this.props.deleteLastPolygon}
                changeFowDrawMode={this.props.changeFowDrawMode}
                setFow={this.props.setFow}
                fowMode={this.props.fowMode}
              />
            )}

            {this.state.tab === "markers" && (
              <MarkersPane
                addNewMarker={this.props.addNewMarker}
                setMarkerAdding={this.props.setMarkerAdding}
                gameData={this.props.gameData}
                storeMarker={this.props.storeMarker}
                deleteMarker={this.props.deleteMarker}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
