import "react-toastify/dist/ReactToastify.css";
import "react-chat-elements/dist/main.css";

import Game from "./Game";
import React from "react";
import {DICE_SERVICE_URI, SOCKET_URI} from "../constants/uri";
import Upload from "./Upload";
import autoBind from "react-autobind";
import io from "socket.io-client";
import store from "store";
import {toast} from "react-toastify";
import {withRouter} from "react-router-dom";
import {Helmet} from "react-helmet";
import * as signalR from "@microsoft/signalr";
import { map, trim } from "lodash";

class Connection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      uploading: false,
      sessionData: {
        sessionId: this.props.match.params.sessionId || "new",
        gameMaster: "",
        boardUrl: "",
        users: [],
        tokens: [],
      },
      error: true,
      errorMessage: "",
      userId: this.props.location.state ? this.props.location.state.userId : null,
      userName: this.props.location.state ? this.props.location.state.userName : null,
      sessionName: this.props.location.state ? this.props.location.state.sessionName : null,
      isGameMaster: false,
    };

    autoBind(this);
  }

  componentDidMount() {
    // if no user is set, try to get data from local storage
    if (!this.props.location.state && !this.state.userName) {
      try {
        const storedState = store.get("state");
        this.setState((prev) => ({
          ...prev,
          userId: storedState.userId,
          userName: storedState.userName,
        }));
      } catch (error) {
        console.log("Can't find stored data for this user. Redirecting...");
        this.props.history.push("/");
      }
    }

    this.initGame();
    this.login();
  }

  initGame() {
    // SocketIO
    this.initSocketConnection();
    this.setupSocketListeners();
    // SignalR
    this.initSignalRConnection();
    this.setupSignalRListeners();
  }

  // SOCKET IO Connection

  initSocketConnection() {
    this.socket = io.connect(SOCKET_URI);
  }

  setupSocketListeners() {
    this.socket.on("session", this.onSessionDataReceived);
    this.socket.on("reconnect", this.onReconnected);
    this.socket.on("disconnect", this.onClientDisconnected);
  }

  // SIGNAL R Connection

  initSignalRConnection() {
    this.signalRConnection = new signalR.HubConnectionBuilder()
      .withUrl(DICE_SERVICE_URI, {
        withCredentials: false,
      })
      .withAutomaticReconnect()
      .build();

    this.connectToDiceRoom();
  }

  connectToDiceRoom() {
    this.signalRConnection
      .start()
      .then(() => {
        console.log("Connected to Dice Service.");
        this.signalRConnection
          .invoke("EnterRoom", this.state.userName, this.state.sessionData.sessionId)
          .then(() => {
            console.log("Connected to dice room " + this.state.sessionData.sessionId);
          })
          .catch((err) => {
            return console.error(err.toString());
          });
      })
      .catch((err) => {
        console.log("Failed to connect to Dice Service.", err);
      });
  }

  setupSignalRListeners() {
    this.signalRConnection.on("RolledDice", (nick, roll) => {
      if (nick !== this.state.userName) {
        return;
      };
      
      let formattedDetails = roll.resultDetails.replace(/\|[sS](\d+)\|/g, "$1 🟩").replace(/\|[fF](\d+)\|/g, "$1 🟥").replace(/(\[.+?\])/g, " $1 ").trim();
      let chatMsg = `${nick} rolled ${roll.diceCode} = ${roll.result} (${formattedDetails})`;
      this.socket.emit("chat", chatMsg, "Server", this.state.sessionData.sessionId);
    });
  }

  disconnectFromDiceRoom() {
    this.signalRConnection.invoke("LeaveRoom").catch(function (err) {
      return console.error(err.toString());
    });
  }

  onClientDisconnected() {
    toast.error("Server connection lost.");
  }

  onReconnected() {
    this.login();
    toast.success("Reconnected successfully.");
  }

  //
  // Receive data from server
  //

  onSessionDataReceived(session) {
    const updatedSession = JSON.parse(session);
    if (window.location.hostname === "localhost") {
      console.log("DEBUG DATA:");
      console.log(JSON.parse(JSON.stringify(updatedSession)));
    }
    if (
      this.state.sessionData.sessionId === "new" ||
      this.state.sessionData.sessionId !== updatedSession.sessionId
    )
      this.props.history.push(`/session/${updatedSession.sessionId}`);
    const {userId} = this.state;
    this.setState((prev) => ({
      ...prev,
      sessionData: updatedSession,
      isGameMaster: userId === updatedSession.gameMaster ? true : false,
      sessionName: updatedSession.sessionName,
    }));
    // store state in local storage
    store.set("state", this.state);
  }

  //
  // Send data to server
  //

  login() {
    let {userId, userName, sessionName} = this.state;
    let {sessionId} = this.state.sessionData;
    if (!userId || !userName) {
      const storedState = store.get("state");
      if (storedState) {
        userId = storedState.userId || null;
        userName = storedState.userName || null;
        sessionId = storedState.sessionData.sessionId || null;
        sessionName = storedState.sessionData.sessionName || null;
      }
    }

    if (userId && userName) {
      this.socket.emit("login", userId, userName, sessionId, sessionName);
      console.log(
        `Login in with User: ${userName}, Session: ${sessionId}, Session Name: ${sessionName}`
      );
    }
  }

  reLogin() {
    this.socket.emit("disconnect");
    this.socket.close();
    store.clearAll();
    this.props.history.push("/");
  }

  disconnectClient(logout) {
    this.disconnectFromDiceRoom();
    if (logout) {
      this.reLogin();
    }
    if (!logout) {
      this.socket.emit("disconnect");
      this.socket.close();
      store.clearAll();

      this.props.history.push({
        pathname: "/joinsession",
        state: {userId: this.state.userId, userName: this.state.userName},
      });
    }
  }

  handleImageError(src) {
    fetch(src, {
      credentials: "include",
    })
      .then((res) => {
        if (res.status === 401) {
          console.log("Session cookie not recognized, please login again.");
          this.reLogin();
        } else {
          console.log(`Error fetching image ${src} Status: ${res.status} - ${res.statusText}`);
        }
      })
      .catch((err) => console.log(`Could not connect to service. Error: ${err}`));
  }

  sendChatMessage(message) {
    if (message.startsWith("/r ") || message.startsWith("/roll ")) {
      const diceCode = message.replace("/roll ", "").replace("/r ", "");
      this.rollDice(diceCode);
    } else {
      this.socket.emit("chat", message, this.state.userName, this.state.sessionData.sessionId);
    }
  }

  sendLog(log, sessionId) {
    this.socket.emit("log", log, sessionId);
  }

  sendTokenMove(token) {
    this.socket.emit("token_move", token, this.state.sessionData.sessionId);
  }

  sendTokenRemove(tokenId) {
    this.socket.emit("token_remove", tokenId, this.state.sessionData.sessionId);
  }

  sendTokenCollect(tokenId) {
    this.socket.emit("token_collect", tokenId, this.state.sessionData.sessionId);
  }

  sendChangeCategory(id, cat) {
    this.socket.emit("token_category_change", id, cat, this.state.sessionData.sessionId);
  }

  sendChangeCaption(id, cap) {
    this.socket.emit("token_caption_change", id, cap, this.state.sessionData.sessionId);
  }

  sendGridChange(grid, gridSize, gridLineWidth, gridLineColor, gridOffsetX, gridOffsetY) {
    this.socket.emit(
      "grid_change",
      grid,
      gridSize,
      gridLineWidth,
      gridLineColor,
      gridOffsetX,
      gridOffsetY,
      this.state.sessionData.sessionId,
      this.state.sessionData.manageId
    );
  }

  selectBoard(boardId) {
    this.socket.emit("select_board", boardId, this.state.sessionData.sessionId);
  }

  manageBoard(manageId) {
    this.socket.emit("manage_board", manageId, this.state.sessionData.sessionId);
  }

  deleteBoard(boardId) {
    this.socket.emit("delete_board", boardId, this.state.sessionData.sessionId);
  }

  handleFileUpload(event, type, sessionId, userId = null, boardId = null) {
    this.setState((prev) => ({
      ...prev,
      uploading: true,
    }));

    Upload.upload(event, type, sessionId, userId)
      .then(() => {
        this.sendLog("Upload succeeded.", sessionId);
        this.setState((prev) => ({
          ...prev,
          uploading: false,
        }));
      })
      .catch((err) => console.log(err));
  }

  handleCopyTokens(boardId, tokenList) {
    this.socket.emit("copy_tokens", boardId, tokenList, this.state.sessionData.sessionId);
  }

  handleMountToken(boardId, token) {
    this.socket.emit("mount_token", token, this.state.sessionData.sessionId, boardId);
  }

  handleTokenMultiChange(id, multi) {
    this.socket.emit("token_multi_change", id, multi, this.state.sessionData.sessionId);
  }

  sendAvatarRemove(avatarId, userId, userName) {
    this.socket.emit("remove_avatar", avatarId, userId, userName, this.state.sessionData.sessionId);
  }

  setFow(enabled, boardId) {
    this.socket.emit("set_fow", enabled, boardId, this.state.sessionData.sessionId);
  }

  setTags(tags, tokenId) {
    this.socket.emit("set_tags", tags, tokenId, this.state.sessionData.sessionId);
  }

  deleteFow(boardId) {
    this.socket.emit("delete_fow", boardId, this.state.sessionData.sessionId);
    toast.success("Deleted Fog of War mask.");
  }

  deleteAllPolygons(boardId) {
    this.socket.emit("delete_all_polygons", boardId, this.state.sessionData.sessionId);
    toast.success("Deleted all FOW polygons.");
  }

  deleteLastPolygon(boardId) {
    this.socket.emit("delete_last_polygon", boardId, this.state.sessionData.sessionId);
    toast.success("Deleted last drawing.");
  }

  freePolygon(boardId, polygon) {
    this.socket.emit("free_polygon", boardId, polygon, this.state.sessionData.sessionId);
  }

  fogPolygon(boardId, polygon) {
    this.socket.emit("fog_polygon", boardId, polygon, this.state.sessionData.sessionId);
  }

  rollDice(diceCode) {
    this.signalRConnection.invoke("RollDice", diceCode).catch(function (err) {
      return console.error(err.toString());
    });
  }

  addMarker(marker, boardId) {
    this.socket.emit("add_marker", marker, boardId, this.state.sessionData.sessionId);
  }

  storeMarker(marker) {
    this.socket.emit("store_marker", marker, this.state.sessionData.sessionId);
  }

  deleteMarker(markerId) {
    this.socket.emit("delete_marker", markerId, this.state.sessionData.sessionId);
  }

  moveMarker(marker) {
    this.socket.emit("move_marker", marker, this.state.sessionData.sessionId);
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {this.state.sessionName ? `CTT - ${this.state.sessionName}` : "Campaign Table Top"}
          </title>
        </Helmet>
        <Game
          sessionData={this.state.sessionData}
          userId={this.state.userId}
          userName={this.state.userName}
          isGameMaster={this.state.isGameMaster}
          onDisconnectHandler={this.disconnectClient}
          sendChatMessage={this.sendChatMessage}
          sendTokenMove={this.sendTokenMove}
          sendTokenRemove={this.sendTokenRemove}
          sendTokenCollect={this.sendTokenCollect}
          sendGridChange={this.sendGridChange}
          sendChangeCategory={this.sendChangeCategory}
          sendChangeCaption={this.sendChangeCaption}
          handleFileUpload={this.handleFileUpload}
          handleCopyTokens={this.handleCopyTokens}
          handleTokenMultiChange={this.handleTokenMultiChange}
          handleMountToken={this.handleMountToken}
          handleRemoveAvatar={this.sendAvatarRemove}
          selectBoard={this.selectBoard}
          manageBoard={this.manageBoard}
          deleteBoard={this.deleteBoard}
          freePolygon={this.freePolygon}
          fogPolygon={this.fogPolygon}
          setFow={this.setFow}
          deleteFow={this.deleteFow}
          deleteAllPolygons={this.deleteAllPolygons}
          deleteLastPolygon={this.deleteLastPolygon}
          uploading={this.state.uploading}
          reLogin={this.reLogin}
          handleImageError={this.handleImageError}
          setTags={this.setTags}
          addMarker={this.addMarker}
          storeMarker={this.storeMarker}
          deleteMarker={this.deleteMarker}
          moveMarker={this.moveMarker}
        />
      </>
    );
  }
}

export default withRouter(Connection);
