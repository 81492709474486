import React from "react";
import autoBind from "react-autobind";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from "lodash";
import {SOCKET_URI} from "../../constants/uri";

const noDisplayStyle = {display: "none"};
const avaStyle = {width: "140px", height: "128px", padding: "8px"};
const imgHeight = {height: "110px"};

export default class AvatarBrowser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
    };

    this.inputOpenFileRef = React.createRef();
    autoBind(this);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      this.setState((prev) => ({
        user: this.props.user,
      }));
    }
  }

  showOpenFileDlg() {
    this.inputOpenFileRef.current.click();
  }

  handleAvatarFileChanged(e) {
    this.props.handleFileUpload(e, "avatar", this.props.sessionId, this.props.userId);
    e.target.value = null;
  }

  deleteAvatar(e) {
    this.props.handleDeleteAvatar(
      parseInt(this.state.user.avatar.substring(0, this.state.user.avatar.indexOf("?")))
    );
  }

  render() {
    return (
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Change Avatar</p>
            <button
              className="delete"
              aria-label="delete"
              onClick={this.props.handleCloseAvatarBrowser}
            ></button>
          </header>
          <input
            ref={this.inputOpenFileRef}
            type="file"
            style={noDisplayStyle}
            onChange={this.handleAvatarFileChanged}
          />
          <section className="modal-card-body">
            <div className="button is-outlined" onClick={this.showOpenFileDlg}>
              <span className="icon">
                <FontAwesomeIcon icon={Icons.faUpload} />
              </span>
              <span>Upload a new avatar</span>
            </div>
            {this.state.user && (
              <div className="card" style={avaStyle}>
                <div className="columns">
                  <div className="column is-11">
                    <div className="card-image">
                      <img
                        style={imgHeight}
                        src={`${SOCKET_URI}/download/token/${this.state.user.avatar}`}
                        alt={this.state.user.userName}
                      />
                    </div>
                  </div>
                  <div className="column is-1">
                    <button className="delete" aria-label="delete" onClick={this.deleteAvatar}></button>
                  </div>
                </div>
              </div>
            )}
          </section>
          <footer className="modal-card-foot">
            <button className="button is-success" onClick={this.props.handleCloseAvatarBrowser}>
              Done
            </button>
          </footer>
        </div>
      </div>
    );
  }
}
