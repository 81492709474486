import React from "react";
import _ from "lodash";
import autoBind from "react-autobind";
import freePolygon from "./Fow/freePolygon";
import fogPolygon from "./Fow/fogPolygon";

const canvasStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  pointerEvents: "none",
};

export default class MaskOnly extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fowMask: this.props.fowMask || null,
      boardWidth: this.props.boardWidth || null,
      boardHeight: this.props.boardHeight || null,
    };

    this.maskRef = React.createRef();
    autoBind(this);
  }

  componentDidUpdate(prevProps) {
    this.updateMask();
    if (!_.isEqual(prevProps, this.props)) {
      this.setState(
        {
          fowMask: this.props.fowMask,
          boardWidth: this.props.boardWidth,
          boardHeight: this.props.boardHeight,
        },
        this.updateMask()
      );
    }
  }

  updateMask() {
    const ctx = this.maskRef.current.getContext("2d");
    const fowMask = this.props.fowMask || [];

    ctx.beginPath();

    ctx.fillStyle = "darkgray";

    const maskCopy = [...fowMask];
    const mainMask = maskCopy[0];
    const polygonMask = maskCopy.splice(1);

    // draw main FOW rectangle
    ctx.fillRect(mainMask.x, mainMask.y, mainMask.width, mainMask.height);

    polygonMask.forEach((poly) => {
      if (poly.coords && poly.coords.length > 3) {
        if (poly.mode === "fog") {
          fogPolygon(ctx, poly.coords, false);
        } else {
          freePolygon(ctx, poly.coords);
        }
      }
    });

    ctx.globalAlpha = 1.0;
  }

  render() {
    return (
      <canvas
        ref={this.maskRef}
        id="mask-only"
        className="mask-only"
        width={this.state.boardWidth ? this.state.boardWidth : 1}
        height={this.state.boardHeight ? this.state.boardHeight : 1}
        style={canvasStyle}
      />
    );
  }
}
