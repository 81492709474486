import React from "react";
import {Helmet} from "react-helmet";
import {Route, Switch} from "react-router-dom";
import OidcCallback from "../components/OidcCallback";
import Sources from "../components/Sources";
import LoginPage from "../containers/LoginPage";
import SessionPage from "../containers/SessionPage";
import userManager from "../userManager";
import Connection from "./Connection";
import ErrorPage from "./../components/ErrorPage";
import {ToastContainer} from "react-toastify";
import {DragDropContext} from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import Documentation from "./../components/Documentation";

class App extends React.PureComponent {
  constructor() {
    super();

    this.userManager = userManager;
  }

  render() {
    return (
      <>
        <Helmet title="Campaign Table Top" />

        <Switch>
          <Route exact path={["/", "/index.html"]}>
            <LoginPage userManager={this.userManager} />
          </Route>

          <Route exact path="/joinsession" component={SessionPage} />
          <Route exact path="/session" component={Connection} />
          <Route exact path="/session/:sessionId" component={Connection} />
          <Route exact path="/sources" component={Sources} />
          <Route exact path="/beginners-guide" component={Documentation} />

          <Route exact path="/oidc-callback">
            <OidcCallback userManager={this.userManager} />
          </Route>
          <Route exact path="/loginerror" component={ErrorPage} />

          <Route>
            <ErrorPage state={{title: "Not Found", content: "Page can't be found :("}} />
          </Route>
          <ToastContainer autoClose={2000} hideProgressBar={true} position="top-right" />
        </Switch>
      </>
    );
  }
}

export default DragDropContext(HTML5Backend)(App);
