export default function drawPolygon(ctxMask, clicks) {
  function drawPolygon() {
    ctxMask.fillStyle = "rgba(127,127,127,0.3)";
    ctxMask.strokeStyle = "#df4b26";
    ctxMask.lineWidth = 5;

    ctxMask.beginPath();
    ctxMask.moveTo(clicks[0].x, clicks[0].y);
    for (var i = 1; i < clicks.length; i++) {
      ctxMask.lineTo(clicks[i].x, clicks[i].y);
    }
    ctxMask.closePath();
    ctxMask.fill();
    ctxMask.stroke();
  }

  function drawPoints() {
    ctxMask.strokeStyle = "#df4b26";
    ctxMask.lineJoin = "round";
    ctxMask.lineWidth = 10;

    for (var i = 0; i < clicks.length; i++) {
      if (i === 0) {
        ctxMask.strokeStyle = "#00F";
        ctxMask.beginPath();
        ctxMask.arc(clicks[i].x, clicks[i].y, 5, 0, 2 * Math.PI, false);
        ctxMask.fillStyle = "#00F";
        ctxMask.fill();
        ctxMask.lineWidth = 20;
        ctxMask.stroke();
      } else {
        ctxMask.strokeStyle = "#df4b26";
        ctxMask.beginPath();
        ctxMask.arc(clicks[i].x, clicks[i].y, 3, 0, 2 * Math.PI, false);
        ctxMask.fillStyle = "#df4b26";
        ctxMask.fill();
        ctxMask.lineWidth = 10;
        ctxMask.stroke();
      }
    }
  }
  if (clicks.length > 0) {
    drawPolygon();
    drawPoints();
  }
}
