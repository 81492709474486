import React from "react";
import {Types} from "../../constants/dndTypes";
import {DropTarget} from "react-dnd";

const dndTarget = {
  drop(props, monitor, component) {
    const item = monitor.getItem();
    const mouseOffset = props.getMousePos();

    const newToken = {
      ...props.tokens.find((t) => t.id === item.id),
      caption: "",
      x: mouseOffset.x,
      y: mouseOffset.y,
      owner: props.userId,
    };
    newToken.owner = newToken.uploader;

    // mount token here
    props.handleMountToken(props.manageId, newToken);

    return item;
  },
};

function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({shallow: true}),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
  };
}

function TokenDropTarget(props) {
  const {connectDropTarget} = props;

  return connectDropTarget(<div>{props.children}</div>);
}

export default DropTarget(Types.TOKEN, dndTarget, collect)(TokenDropTarget);
