import PropTypes from "prop-types";
import React, {useState, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {SOCKET_URI} from "../constants/uri";

function OidcComponent(props) {
  const {message, setMessage} = useState("");

  useEffect(() => {
    props.userManager.signinRedirectCallback().then(
      (user) => {
        setCookie(user.access_token, user.profile.sub).then(() => {
          props.history.push({pathname: "/joinsession", state: user});
        });
      },
      (error) => {
        console.log(error);
        props.history.push({
          pathname: "/loginerror",
          state: {title: "Login Error", content: "Error logging you in :("},
        });
      }
    );
  }, [props.userManager, setMessage, props.history]);

  return <>{message}</>;
}

function setCookie(token) {
  return new Promise((resolve) => {
    fetch(`${SOCKET_URI}/cookie`, {
      headers: {
        "auth-token": token,
      },
      credentials: "include",
    })
      .then(() => {
        console.log("Session cookie set.");
        resolve();
      })
      .catch((err) => console.log(`Error setting cookie: ${err}`));
  });
}

OidcComponent.propTypes = {
  userManager: PropTypes.object,
};

export default withRouter(OidcComponent);
