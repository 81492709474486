// This component gets the converted game data and renders it with components
// - Map with Background image and grid
// - TokenLayer
// - Aditional mask for players, so the tokens/grid are covered

import {Stage} from "react-konva";

import Map from "./Board/Map";
import MaskOnly from "./Board/MaskOnly";
import React from "react";
import TokenLayer from "./Board/TokenLayer";
import {TransformComponent} from "react-zoom-pan-pinch";
import {TransformWrapper} from "react-zoom-pan-pinch";
import _ from "lodash";
import autoBind from "react-autobind";
import styled from "styled-components";
import TokenDropTarget from "./Board/TokenDropTarget";
import MarkerLayer from "./Board/MarkerLayer";

const Viewport = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

const panOptions = {velocity: false, disableOnTarget: ["layer"]};
const wheelOptions = {step: 500.0};

class Board extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gameData: this.props.gameData,
      disablePanning: false,
    };

    autoBind(this);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      this.setState((prev) => ({
        ...prev,
        gameData: this.props.gameData,
      }));
    }
  }

  enablePanning() {
    this.setState((prev) => ({
      ...prev,
      disablePanning: false,
    }));
  }

  disablePanning() {
    this.setState((prev) => ({
      ...prev,
      disablePanning: true,
    }));
  }

  handleMouseMove(e) {
    this.setState((prev) => ({
      ...prev,
      mountCoords: {x: e.offsetX, y: e.offsetY},
    }));
  }

  handleMouseHover(e) {
    if (this.props.gameData && this.props.gameData.markers) {
      const maxCount = this.props.gameData.markers.length;
      let foundMatch = false;
      this.props.gameData.markers.forEach((m, i) => {
        const distance = Math.sqrt(Math.pow(Math.abs(e.offsetX - m.x), 2) + Math.pow(Math.abs(e.offsetY - m.y), 2));
        if (distance < this.props.gameData.gridSize) {
          foundMatch = true;
          this.props.showDescription(m.id);
        }
        if (maxCount - 1 === i && !foundMatch) this.props.hideDescription();
      });
    }
  }

  getMousePos() {
    return this.state.mountCoords;
  }

  render() {
    const transformOptions = {
      limitToBounds: false,
      minScale: 0.25,
      maxScale: 5.0,
      disabled: this.state.disablePanning,
    };

    const chStyle = {
      position: "absolute",
      cursor: this.props.addNewMarker ? "crosshair" : "default",
    };

    const absoluteStyle = {
      position: "absolute",
    };

    return (
      <TokenDropTarget
        handleMountToken={this.props.handleMountToken}
        boardId={this.state.gameData.boardId}
        manageId={this.props.gameData.manageId}
        tokens={this.state.gameData.tokens}
        userId={this.props.userId}
        getMousePos={this.getMousePos}
      >
        <TransformWrapper
          options={transformOptions}
          defaultScale={0.75}
          defaultPositionX={-500}
          defaultPositionY={-500}
          pan={panOptions}
          wheel={wheelOptions}
        >
          {this.state.gameData && (
            <TransformComponent>
              <Viewport width={window.innerWidth} height={window.innerHeight}>
                <Map
                  src={this.state.gameData.board}
                  grid={this.state.gameData.grid}
                  boardWidth={this.state.gameData.boardWidth}
                  boardHeight={this.state.gameData.boardHeight}
                  gridSize={this.state.gameData.gridSize}
                  gridLineWidth={this.state.gameData.gridLineWidth}
                  gridLineColor={this.state.gameData.gridLineColor}
                  gridOffsetX={this.state.gameData.gridOffsetX}
                  gridOffsetY={this.state.gameData.gridOffsetY}
                  handleBoardClick={this.props.handleBoardClick}
                  handleMouseDown={this.disablePanning}
                  handleMouseUp={this.enablePanning}
                  handleStartMount={this.disablePanning}
                  handleEndMount={this.enablePanning}
                  handleMouseMove={this.handleMouseMove}
                  fowMode={this.props.fowMode}
                  drawing={this.state.drawing}
                  drawFow={this.props.drawFow}
                  reload={this.state.gameData.reload}
                  fowMask={this.state.gameData.fowMask}
                  fowEnabled={this.props.gameData.fowEnabled}
                  isGameMaster={this.props.isGameMaster}
                  reLogin={this.props.reLogin}
                  handleMouseHover={this.handleMouseHover}
                  sessionId={this.props.sessionId}
                />
                {this.props.isGameMaster && this.state.gameData.board && (
                  <Stage
                    width={this.state.gameData.boardWidth ? this.state.gameData.boardWidth : 1}
                    height={this.state.gameData.boardHeight ? this.state.gameData.boardHeight : 1}
                    style={chStyle}
                  >
                    <MarkerLayer
                      gameData={this.state.gameData}
                      handleBoardClick={this.props.handleBoardClick}
                      addNewMarker={this.props.addNewMarker}
                      markers={this.props.gameData.markers}
                      isMarkerMenuOpen={this.props.showMarkerMenu}
                      moveMarker={this.props.moveMarker}
                    />
                  </Stage>
                )}
                {this.state.gameData.board && !this.props.showMarkerMenu && (
                  <Stage
                    width={this.state.gameData.boardWidth ? this.state.gameData.boardWidth : 1}
                    height={this.state.gameData.boardHeight ? this.state.gameData.boardHeight : 1}
                    style={absoluteStyle}
                  >
                    <TokenLayer
                      tokens={this.state.gameData.mountedTokens}
                      users={this.state.gameData.users}
                      userId={this.props.userId}
                      handleTokenDragEnd={this.props.handleTokenDragEnd}
                      handleTokenClick={this.props.handleTokenClick}
                      selectedTokens={this.props.selectedTokens}
                      grid={this.state.gameData.grid}
                      gridSize={this.state.gameData.gridSize}
                      boardId={this.state.gameData.boardId}
                      isGameMaster={this.props.isGameMaster}
                      manageId={this.props.gameData.manageId}
                      fowMode={this.props.fowMode}
                      handleImageError={this.props.handleImageError}
                    />
                  </Stage>
                )}
                {!this.props.isGameMaster && this.props.gameData.fowEnabled && (
                  <MaskOnly
                    fowMask={this.props.fowMask}
                    boardWidth={this.state.gameData.boardWidth}
                    boardHeight={this.state.gameData.boardHeight}
                  />
                )}
              </Viewport>
            </TransformComponent>
          )}
        </TransformWrapper>
      </TokenDropTarget>
    );
  }
}

export default Board;
