import React from "react";
import BulmaTagsInput from "@creativebulma/bulma-tagsinput";

export default class TagsInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || "",
    };
  }

  componentDidMount() {
    this.tagsInput = new BulmaTagsInput(this.refs.tagsinput, this.state.options);

    this.tagsInput.on("after.add", () => {
      this.onTagsChange(this.props.id);
    });
    this.tagsInput.on("after.remove", () => {
      this.onTagsChange(this.props.id);
    });
  }

  onTagsChange(id) {
    this.props.onTagChange(this.tagsInput.items, id);
  }

  render() {
    const {value} = this.state;
    const {id} = this.props;

    return (
      <div className="field">
        <div className="control">
          <input
            ref="tagsinput"
            id={id}
            type="text"
            value={value}
            onChange={() => this.onTagsChange(id)}
          />
        </div>
      </div>
    );
  }
}
