import React, {Fragment} from "react";
import {Layer} from "react-konva";
import MountedToken from "./MountedToken";
import _ from "lodash";
import autoBind from "react-autobind";

class TokenLayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {tokens: this.props.tokens || [], users: this.props.users || []};

    this.canvasRef = React.createRef();
    autoBind(this);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      this.setState((prev) => ({
        ...prev,
        tokens: this.props.tokens || [],
        users: this.props.users || [],
      }));
    }
  }

  tokenMouseDown(e) {
    this.canvasRef.current.canvas._canvas.classList.add("tokenlayer");
  }

  tokenMouseUp() {
    this.canvasRef.current.canvas._canvas.classList.remove("tokenlayer");
  }

  render() {
    const absoluteStyle = {
      position: "absolute",
    };

    return (
      <Layer ref={this.canvasRef} style={absoluteStyle}>
        <>
          {this.props.isGameMaster &&
            this.state.tokens
              .filter((t) => t.boardId === this.props.manageId && t.visible)
              .map((token, i) => (
                <Fragment key={`fragment-${token.id}`}>
                  <MountedToken
                    token={token}
                    id={token.id}
                    handleTokenDragEnd={!this.props.fowMode ? this.props.handleTokenDragEnd : null}
                    handleTokenClick={!this.props.fowMode ? this.props.handleTokenClick : null}
                    handleMouseDown={!this.props.fowMode ? this.tokenMouseDown : null}
                    handleMouseUp={!this.props.fowMode ? this.tokenMouseUp : null}
                    grid={this.props.grid}
                    gridSize={this.props.gridSize * token.multi}
                    draggable={!this.props.fowMode}
                    handleImageError={this.props.handleImageError}
                    glow={this.props.selectedTokens.includes(token.id)}
                  />
                </Fragment>
              ))}
          {!this.props.isGameMaster &&
            this.state.tokens
              .filter((t) => t.boardId === this.props.boardId && t.visible)
              .map((token) => (
                <Fragment key={`fragment-${token.id}`}>
                  <MountedToken
                    token={token}
                    id={token.id}
                    handleTokenDragEnd={this.props.handleTokenDragEnd}
                    handleTokenClick={this.props.handleTokenClick}
                    handleMouseDown={this.tokenMouseDown}
                    handleMouseUp={this.tokenMouseUp}
                    grid={this.props.grid}
                    gridSize={this.props.gridSize * token.multi}
                    draggable={token.owner === this.props.userId ? true : ""}
                    handleImageError={this.props.handleImageError}
                  />
                </Fragment>
              ))}
        </>
      </Layer>
    );
  }
}

export default TokenLayer;
