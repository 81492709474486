import React from "react";
import _ from "lodash";
import autoBind from "react-autobind";
import freePolygon from "./Fow/freePolygon";
import fogPolygon from "./Fow/fogPolygon";
import drawRectGrid from "./Fow/drawRectGrid";
import drawHexGrid from "./Fow/drawHexGrid";
import drawPolygon from "./Fow/drawPolygon";

const canvasStyle = {position: "absolute"};

export default class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: this.props.src || null,
      grid: this.props.grid || "hex",
      gridSize: this.props.gridSize || 100,
      gridLineWidth: this.props.gridLineWidth || 2,
      gridLineColor: this.props.gridLineColor || "#000000",
      gridOffsetX: this.props.gridOffsetX || 0,
      gridOffsetY: this.props.gridOffsetY || 0,
      imageFile: null,
      reload: this.props.reload,
      firstLoad: true,
      clicks: [],
    };

    this.mapRef = React.createRef();
    this.maskRef = React.createRef();
    autoBind(this);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      this.setState((prev) => ({
        ...prev,
        image: this.props.src,
        grid: this.props.grid,
        boardWidth: this.props.boardWidth,
        boardHeight: this.props.boardHeight,
        gridSize: this.props.gridSize,
        gridLineWidth: this.props.gridLineWidth || 2,
        gridLineColor: this.props.gridLineColor || "#000000",
        gridOffsetX: this.props.gridOffsetX || 0,
        gridOffsetY: this.props.gridOffsetY || 0,
        reload: this.props.reload,
      }));
      setTimeout(() => {
        if ((this.props.reload || this.state.firstLoad) && !this.props.drawing) this.loadMapFile();
        else this.updateMap();
      });
    }
  }

  handleMouseDown(e) {
    if (e.altKey && this.props.fowMode && e.target.nodeName !== "CANVAS") {
      e.stopPropagation();
      return;
    }
    if (e.altKey && this.props.fowMode) {
      this.addPolygonPoint(e);
      this.props.handleMouseDown(e);
      document.addEventListener("mousemove", this.handleMouseMove);
    }
  }

  handleMouseUp(e) {
    document.removeEventListener("mousemove", this.handleMouseMove);
    if (this.props.fowMode) {
      this.props.handleMouseUp(e);
    }
  }

  handleMouseMove(e) {
    this.props.handleMouseMove(e);
  }

  handleMouseHover(e) {
    this.props.handleMouseHover(e);
  }

  handleStartMount(e) {
    this.props.handleStartMount();
    document.addEventListener("dragover", this.handleDragMove);
  }

  handleEndMount(e) {
    this.props.handleEndMount();
    document.removeEventListener("dragover", this.handleDragMove);
  }

  handleDragMove(e) {
    this.props.handleMouseMove(e);
  }

  handleKeyDown(e) {
    if (
      e.key === "Escape" &&
      this.state.clicks.length > 0 &&
      this.props.isGameMaster &&
      this.props.fowEnabled
    ) {
      this.clearClicks();
      this.updateMap();
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.props.handleBoardClick);
    document.addEventListener("mousedown", this.handleMouseDown);
    document.addEventListener("mouseup", this.handleMouseUp);
    document.addEventListener("startmount", this.handleStartMount);
    document.addEventListener("endmount", this.handleEndMount);
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("mousemove", this.handleMouseHover);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.props.handleBoardClick);
    document.removeEventListener("mousedown", this.handleMouseDown);
    document.removeEventListener("mouseup", this.handleMouseUp);
    document.removeEventListener("startmount", this.handleStartMount);
    document.removeEventListener("endmount", this.handleEndMount);
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("mousemove", this.handleMouseHover);
  }

  loadMapFile() {
    if (this.props.src && !this.props.src.includes("undefined")) {
      const url = `${this.props.src}?sessionId=${this.props.sessionId}`;

      const options = {
        method: "GET",
        responseType: "blob",
        credentials: "include",
      };

      fetch(url, options)
        .then((response) => {
          if (response.status === 401) {
            // cookie not recognized -> need to login again
            this.props.reLogin();
            return null;
          }
          return response.blob();
        })
        .then((blob) => {
          if (!blob) return;
          const imageUrl = window.URL.createObjectURL(blob);

          this.setState((prev) => ({
            ...prev,
            imageFile: imageUrl,
            firstLoad: false,
          }));
          setTimeout(() => this.updateMap());
        });
    }
  }

  updateMap() {
    const ctx = this.mapRef.current.getContext("2d");
    const ctxMask = this.maskRef.current.getContext("2d");
    const bw = this.props.boardWidth || this.state.boardWidth || 1;
    const bh = this.props.boardHeight || this.state.boardHeight || 1;
    const grid = this.props.grid || this.state.grid;
    const gridSize = this.props.gridSize || this.state.gridSize;
    const lw = this.props.gridLineWidth || this.state.gridLineWidth;
    const lc = this.props.gridLineColor || this.state.gridLineColor;
    const ox = this.props.gridOffsetX || this.state.gridOffsetX;
    const oy = this.props.gridOffsetY || this.state.gridOffsetY;
    const {fowEnabled, isGameMaster, fowMask} = this.props;
    const clicks = this.state.clicks;
    const map = new Image();
    map.src = this.state.imageFile;

    map.onload = function () {
      // clear canvas
      ctx.clearRect(0, 0, bw, bh);
      ctxMask.clearRect(0, 0, bw, bh);
      ctx.beginPath();
      // draw board image
      ctx.drawImage(map, 0, 0);

      if (fowEnabled && fowMask.length !== 0) {
        // draw fow mask

        const maskCopy = [...fowMask];
        const mainMask = maskCopy[0];
        const polygonMask = maskCopy.splice(1);

        ctxMask.clearRect(0, 0, bw, bh);
        ctxMask.beginPath();

        if (isGameMaster) ctxMask.globalAlpha = 0.7;

        ctxMask.fillStyle = "darkgray";

        // draw main FOW rectangle
        ctxMask.fillRect(mainMask.x, mainMask.y, mainMask.width, mainMask.height);

        polygonMask.forEach((poly) => {
          if (poly.coords && poly.coords.length > 3) {
            if (poly.mode === "fog") {
              fogPolygon(ctxMask, poly.coords, isGameMaster);
            } else {
              freePolygon(ctxMask, poly.coords);
            }
          }
        });

        ctxMask.globalAlpha = 1.0;
      }

      // draw grids
      if (grid === "rect") {
        drawRectGrid(ctx, bw, gridSize, ox, oy, bh, lc, lw);
      } else if (grid === "hex") {
        drawHexGrid(ctx, gridSize, lc, lw, ox, oy, bw, bh);
      }

      // draw polygon which is currently created
      drawPolygon(ctxMask, clicks);
    };
  }

  addPolygonPoint(e) {
    // check if click is near first click
    // then complete shape and send it to server
    let newClicks = this.state.clicks;
    let lastClick = false;
    if (newClicks.length > 0) {
      const firstClick = {x: newClicks[0].x, y: newClicks[0].y};
      const curClick = {
        x: e.offsetX,
        y: e.offsetY,
      };
      if (Math.abs(firstClick.x - curClick.x) < 20 && Math.abs(firstClick.y - curClick.y) < 20) {
        lastClick = true;
      }
    }

    if (!lastClick) {
      // just another click to add
      newClicks.push({
        x: e.offsetX,
        y: e.offsetY,
      });

      this.setState((prevState) => ({...prevState, clicks: newClicks}));
    } else {
      // last click of shape => lastClick = firstClick
      newClicks.push({
        x: newClicks[0].x,
        y: newClicks[0].y,
      });
      // send shape to server and clear clicks
      this.props.drawFow(newClicks);
      this.clearClicks();
    }

    this.updateMap();
  }

  clearClicks() {
    this.setState((prevState) => ({...prevState, clicks: []}));
  }

  render() {
    return (
      <React.Fragment>
        <canvas
          ref={this.mapRef}
          id="map"
          className="map"
          width={this.state.boardWidth ? this.state.boardWidth : 1}
          height={this.state.boardHeight ? this.state.boardHeight : 1}
          style={canvasStyle}
        />
        <canvas
          ref={this.maskRef}
          id="mask"
          className="map"
          width={this.state.boardWidth ? this.state.boardWidth : 1}
          height={this.state.boardHeight ? this.state.boardHeight : 1}
          style={canvasStyle}
        />
      </React.Fragment>
    );
  }
}
