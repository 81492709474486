export default function freePolygon(ctx, coords) {
  ctx.strokeStyle = "#df4b26";
  ctx.lineWidth = 5;

  ctx.globalCompositeOperation = "destination-out";

  ctx.beginPath();
  ctx.moveTo(coords[0].x, coords[0].y);
  for (var i = 1; i < coords.length; i++) {
    ctx.lineTo(coords[i].x, coords[i].y);
  }
  ctx.closePath();
  ctx.fill();
  ctx.globalCompositeOperation = "source-over";

  ctx.globalAlpha = 1;
}
