export default function drawHexGrid(ctx, gridSize, lc, lw, ox, oy, bw, bh) {
  const hexagonAngle = 0.523598776; // 30 degrees in radians
  const sideLength = gridSize / 2;
  const hexHeight = Math.sin(hexagonAngle) * sideLength;
  const hexRadius = Math.cos(hexagonAngle) * sideLength;
  const hexRectangleHeight = sideLength + 2 * hexHeight;
  const hexRectangleWidth = 2 * hexRadius;

  // h and w are measured in hex-sizes NOT px
  const boardWidth = bw / hexRectangleWidth;
  const boardHeight = bh / (2 * hexHeight);

  ctx.strokeStyle = lc;
  ctx.lineWidth = lw;

  drawGrid(ctx, boardWidth, boardHeight);

  function drawGrid(ctx, width, height) {
    for (let i = 0; i < width; ++i) {
      for (let j = 0; j < height; ++j) {
        drawHexagon(
          ctx,
          i * hexRectangleWidth + (j % 2) * hexRadius,
          j * (sideLength + hexHeight),
          false
        );
      }
    }
  }

  function drawHexagon(ctx, x, y, fill) {
    ctx.beginPath();
    ctx.moveTo(x + hexRadius + ox, y + oy);
    ctx.lineTo(x + hexRectangleWidth + ox, y + oy + hexHeight);
    ctx.lineTo(x + hexRectangleWidth + ox, y + oy + hexHeight + sideLength);
    ctx.lineTo(x + hexRadius + ox, y + oy + hexRectangleHeight);
    ctx.lineTo(x + ox, y + oy + sideLength + hexHeight);
    ctx.lineTo(x + ox, y + oy + hexHeight);
    ctx.closePath();

    if (fill) {
      ctx.fill();
    } else {
      ctx.stroke();
    }
  }
}
