/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from "react";
import autoBind from "react-autobind";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from "lodash";
import {toast} from "react-toastify";

import TokenImage from "../../Board/TokenImage";
import TokenImageDND from "../../Board/TokenImageDND";

const thumbnailStyle = {
  maxWidth: "150px",
  maxHeight: "100px",
  width: "auto",
  height: "auto",
};

const height48 = {
  height: "48px",
};

const topMargin = {
  marginTop: "8px",
};

const fullWidth = {
  width: "100%",
};

const multiLabel = {
  fontWeight: "bold",
  padding: "4px",
  width: "40px",
};

class TokensPane extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: "board",
      targetBoard: -1,
      tags: this.findAllTags(),
      filteredTokens: this.props.allTokens,
      currentTokenFilter: "unmounted",
      tokenCaption: "",
    };

    autoBind(this);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      this.setState((prev) => ({
        ...prev,
        tokenCaption:
          this.props.selectedTokens.length > 0
            ? this.props.mountedTokens.find((t) => t.id === this.props.selectedTokens[0]).caption
            : "",
        tags: this.findAllTags(),
      }));
      if (this.state.currentTokenFilter === "unmounted") {
        this.showTokenLibrary();
      } else {
        this.showMountedTokens();
      }
    }
  }

  collectToken() {
    this.props.handleCollectToken(this.props.selectedTokens[0]);
    if (this.state.currentTokenFilter === "mounted") this.showMountedTokens();
  }

  tokenTargetChange(e) {
    if (!this.props.boards[e.target.value - 1]) {
      this.setState((prev) => ({
        ...prev,
        targetBoard: -1,
      }));
    } else {
      const targetBoard = this.props.boards[e.target.value - 1].boardId;

      this.setState((prev) => ({
        ...prev,
        targetBoard,
      }));
    }
  }

  filterByTag(tag) {
    let newTokens = [];

    if (this.props.allTokens) {
      this.props.allTokens.forEach((t) => {
        if (t.tags && t.tags.includes(tag)) newTokens.push(t);
      });
    }

    if (!tag) newTokens = this.props.allTokens;

    this.setState((prev) => ({
      ...prev,
      filteredTokens: newTokens,
      currentTokenFilter: "unmounted",
    }));
  }

  removeTagFilter() {
    this.filterByTag("");
  }

  handleTokenCaptionChange = (id) => {
    this.props.handleChangeCaption(id, this.state.tokenCaption);
  };

  handleTokenCaptionKeyPress(e) {
    const cap = e.target.value;

    this.setState((prev) => ({
      ...prev,

      tokenCaption: cap,
    }));
  }

  showMountedTokens() {
    let newTokens = [];

    if (this.props.mountedTokens) {
      this.props.mountedTokens.forEach((t) => {
        if (t.boardId === this.props.manageId) newTokens.push(t);
      });
    }

    this.setState((prev) => ({
      ...prev,
      filteredTokens: newTokens,
      currentTokenFilter: "mounted",
    }));
  }

  showTokenLibrary() {
    this.setState((prev) => ({
      ...prev,
      filteredTokens: this.props.allTokens,
      currentTokenFilter: "unmounted",
    }));
  }

  findAllTags() {
    let tags = [];
    if (this.props.allTokens) {
      this.props.allTokens.forEach((t) => {
        if (t.tags) {
          t.tags.forEach((singleTag) => {
            tags.push(singleTag);
          });
        }
      });
    }

    return _.uniq(tags);
  }

  copyTokens() {
    // check, if valid target is selected
    if (this.props.boards.some((board) => board.boardId.toString() === this.state.targetBoard.toString())) {
      this.props.handleCopyTokens(this.state.targetBoard);
      toast.success("Tokens successfully copied!");
    } else {
      toast.error("Error copying tokens!");
    }
  }

  changeTokenMulti(id, multi) {
    this.setState((prev) => ({
      ...prev,
      multi: multi,
    }));
    this.props.handleTokenMultiChange(id, multi);
  }

  render() {
    const tokenListStyle = {
      overflowY: "scroll",
      overflowX: "hidden",
      height: window.innerHeight - 500,
    };

    return (
      <>
        <div className="tabs is-toggle is-toggle-rounded">
          <ul>
            <li className={this.state.currentTokenFilter === "unmounted" ? "is-active" : ""}>
              <a className="button" onClick={this.showTokenLibrary}>
                Token library
              </a>
            </li>
            <li className={this.state.currentTokenFilter === "mounted" ? "is-active" : ""}>
              <a className="button" onClick={this.showMountedTokens}>
                Mounted tokens
              </a>
            </li>
          </ul>
        </div>

        {this.state.currentTokenFilter === "unmounted" && (
          <>
            <div className="buttons are-small">
              <button className="button" onClick={this.removeTagFilter}>
                <b>Show all</b>
              </button>
              {this.state.tags &&
                this.state.tags.sort().map((tag) => (
                  <button className="button" key={`tag-${tag}`} onClick={() => this.filterByTag(tag)}>
                    {tag}
                  </button>
                ))}
            </div>

            <div className="field" style={tokenListStyle}>
              {this.state.filteredTokens.map((t, i) => (
                <TokenImageDND token={t} key={`tag-tk-${t.id}`} id={t.id} />
              ))}
            </div>
          </>
        )}

        {this.state.currentTokenFilter === "mounted" && (
          <>
            <div className="field" style={tokenListStyle}>
              {this.state.filteredTokens.map((t) => (
                <TokenImage
                  token={t}
                  handleTokenClick={this.props.handleTokenClick}
                  key={`tag-fld-${t.id}`}
                  id={t.id}
                  selected={this.props.selectedTokens.includes(t.id)}
                />
              ))}
            </div>
          </>
        )}

        <div style={height48}>
          <button className="button is-info is-pulled-right" onClick={this.props.handleOpenTokenManager}>
            <span className="icon">
              <FontAwesomeIcon icon={Icons.faUpload} />
            </span>
            <span>Edit Tokens</span>
          </button>
        </div>

        {this.props.selectedTokens.length === 1 && (
          <div style={this.centerStyle}>
            <label>Selected Token: </label>

            {this.props.mountedTokens
              .filter((t) => t.id === this.props.selectedTokens[0])
              .map((t, i) => (
                <div className="columns" key={`cap-change-${t.id}`}>
                  <div className="column is-4">
                    <TokenImage token={t} />
                  </div>
                  <div className="column">
                    <div className="rows">
                      <div className="row">
                        <input
                          className="input is-info"
                          type="text"
                          placeholder="Token caption"
                          value={this.state.tokenCaption || t.caption}
                          onBlur={() => this.handleTokenCaptionChange(t.id)}
                          onChange={this.handleTokenCaptionKeyPress}
                          key={`cap-inp-${t.id}`}
                        />
                      </div>

                      <div className="flex-grouped">
                        <label>Size Multiplier:</label>

                        <label style={multiLabel}>{t.multi}</label>

                        <div style={{flexGrow: 1}}>
                          <input
                            style={fullWidth}
                            className="slider"
                            step="0.5"
                            min="0.5"
                            max="10"
                            value={t.multi}
                            type="range"
                            onChange={(e) => this.changeTokenMulti(t.id, e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      className="button is-danger is-small is-pulled-right"
                      onClick={this.collectToken}
                      style={topMargin}
                    >
                      Remove Token
                    </button>
                  </div>
                </div>
              ))}
          </div>
        )}

        {this.props.selectedTokens.length > 1 && (
          <>
            <label>Selected Tokens: </label>
            <b>{this.props.selectedTokens.length}</b>
            <p>
              <label>Copy to other board - select target:</label>
            </p>

            <div className="select">
              <select onChange={this.tokenTargetChange}>
                <option>Choose target</option>
                {this.props.boards
                  .sort((a, b) => a.boardId - b.boardId)
                  .map((b, i) => (
                    <option key={`$b-${b.boardId}`}>{i + 1}</option>
                  ))}
              </select>
            </div>
            {this.props.boards.find((b) => b.boardId === this.state.targetBoard) && (
              <img
                src={this.props.boards.find((b) => b.boardId === this.state.targetBoard).thumbnail}
                onError={() =>
                  this.props.handleImageError(
                    this.props.boards.find((b) => b.boardId === this.state.targetBoard).thumbnail
                  )
                }
                style={thumbnailStyle}
                alt=""
              />
            )}
            {this.state.targetBoard >= 0 && (
              <button className="button" onClick={this.copyTokens}>
                Copy
              </button>
            )}
          </>
        )}
      </>
    );
  }
}

export default TokensPane;
