import React from "react";
import {toast, ToastContainer} from "react-toastify";
import autoBind from "react-autobind";
import store from "store";
import _ from "lodash";

import NavBar from "./../components/UI/NavBar";

import {SOCKET_URI} from "./../constants/uri";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Moment from "react-moment";
import {shortenText} from "./../utils/shortenText";

const cardStyle = {minWidth: "800px", padding: "16px", borderRadius: "6px", opacity: "0.9"};
const sessionCardStyle = {
  margin: "6px",
  borderRadius: "6px",
  backgroundColor: "#a9d3f0",
};

const width_200 = {width: "200px"};
const width_400 = {width: "400px"};

export default class SessionPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: this.props.location.state.userId
        ? this.props.location.state.userId
        : _.get(props, "location.state.profile.sub", "id_not_found"),
      userName: this.props.location.state.userName
        ? this.props.location.state.userName
        : _.get(props, "location.state.profile.nickname", "")
        ? _.get(props, "location.state.profile.nickname", "")
        : _.get(props, "location.state.profile.given_name", "") ||
          _.get(props, "location.state.profile.preferred_username", "no_name"),
      sessionId: "",
      sessionName: "",
      sessionHistory: [],
    };

    autoBind(this);
  }

  componentDidMount() {
    // check if user credentials are NOT provided
    if (!this.state.userId || !this.state.userName) {
      // check if user/id are stored in store
      try {
        const storedState = store.get("state");
        this.setState((prev) => ({
          ...prev,
          userId: storedState.userId,
          userName: storedState.userName,
        }));
        console.log("Found stored user credentials.");
      } catch (error) {
        console.log("Can't find stored data for this user. Redirecting...");
        this.props.history.push("/");
      }
    } else {
      // user credentials are provided -> store them in store
      store.set("state", {
        userId: this.state.userId,
        userName: this.state.userName,
      });
    }

    this.getSessionHistory();
  }

  handleTextChange(e) {
    const input = e.target.value || "";
    if (e.currentTarget.dataset.tag === "id") {
      this.setState((prev) => ({
        ...prev,
        sessionId: input,
      }));
    } else if (e.currentTarget.dataset.tag === "sessionName") {
      this.setState((prev) => ({
        ...prev,
        sessionName: input,
      }));
    }
  }

  getSessionHistory() {
    fetch(`${SOCKET_URI}/sessions/${this.state.userId}`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        if (Object.entries(data).length) {
          const sortedSessions = data;
          sortedSessions.sort((a, b) => new Date(b.lastLoggedIn) - new Date(a.lastLoggedIn));
          this.setState((prev) => ({
            ...prev,
            sessionHistory: sortedSessions || [],
          }));
        }
      });
  }

  selectSession(sessionId) {
    toast.success("Session selected!");
    this.setState((prev) => ({
      ...prev,
      sessionId: sessionId,
    }));
  }

  deleteSession(sessionId) {
    fetch(`${SOCKET_URI}/sessions/${sessionId}`, {
      credentials: "include",
      method: "DELETE",
    }).then((res) => {
      if (res.status === 200) {
        toast.success("Session deleted!");
        this.getSessionHistory();
      } else {
        toast.error("Error deleting session");
        console.log(`Error deleting session ${sessionId} Status: ${res.status} - ${res.statusText}`);
      }
    });
  }

  pushToSession(e) {
    if (e.target.id === "new") {
      if (this.state.name === "") {
        toast.error("Please provide a session name!");
        return;
      }
      // delete old session data from storage
      store.remove("state");
      this.props.history.push({
        pathname: `/session/new`,
        state: {
          userId: this.state.userId,
          userName: this.state.userName,
          sessionName: this.state.sessionName,
        },
      });
    } else if (e.target.id === "existing" && this.state.sessionId) {
      this.props.history.push({
        pathname: `/session/${this.state.sessionId}`,
        state: {
          userId: this.state.userId,
          userName: this.state.userName,
        },
      });
    } else {
      toast.error("Please provide a session id!");
    }
  }

  logout() {
    store.clearAll();
    this.props.history.push("/");
  }

  render() {
    return (
      <>
        <NavBar sessionPage userName={this.state.userName} onDisconnectHandler={this.logout} />
        <section className="hero bg-img-session is-fullheight scrollable">
          <div className="hero-body">
            <div className="container">
              <div className="columns is-centered">
                <div className="card" style={cardStyle}>
                  <div className="card-content">
                    <h1 className="title">Hello {this.state.userName}!</h1>
                    <br />
                    <div className="content">
                      <h2 className="h2">You can create a new session</h2>
                      <div className="field is-grouped">
                        <div className="control">
                          <button className="button is-success" onClick={this.pushToSession} id="new" style={width_200}>
                            Create a new session
                          </button>
                        </div>
                        <div className="control">
                          <input
                            className="input is-info"
                            type="text"
                            placeholder="Please provide a session name!"
                            data-tag="sessionName"
                            onChange={this.handleTextChange}
                            style={width_400}
                            maxLength="48"
                          />
                        </div>
                      </div>

                      <br />
                      <h2 className="h2">...or enter a session id to join an existing session</h2>
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            className="button is-info"
                            onClick={this.pushToSession}
                            id="existing"
                            style={width_200}
                          >
                            Join session
                          </button>
                        </div>
                        <div className="control">
                          <input
                            className="input is-info"
                            type="text"
                            placeholder="Session ID"
                            data-tag="id"
                            value={this.state.sessionId || ""}
                            onChange={this.handleTextChange}
                            style={width_400}
                          />
                        </div>
                      </div>
                      <br />

                      <h2 className="h2">...or choose from recent sessions</h2>
                      <div className="row columns is-multiline is-desktop">
                        {this.state.sessionHistory.map((session) => {
                          return (
                            <div key={`ses-${session.sessionId}`} className={`column is-6`}>
                              <div className="card session-card" style={sessionCardStyle}>
                                <div className="card-content">
                                  {session.gameMaster === session.userId && (
                                    <button
                                      className="delete is-pulled-right"
                                      aria-label="delete"
                                      onClick={() => {
                                        if (window.confirm("Do you really want to delete this session?"))
                                          this.deleteSession(session.sessionId);
                                      }}
                                    />
                                  )}
                                  <div className="content" onClick={() => this.selectSession(session.sessionId)}>
                                    <h3 className="h3">{shortenText(session.name, 48)}</h3>

                                    {session.gameMaster === session.userId ? (
                                      <label className="label">
                                        Role: Game Master &nbsp;
                                        <FontAwesomeIcon icon={Icons.faCrown} />
                                      </label>
                                    ) : (
                                      <label className="label">
                                        Role: Player &nbsp;
                                        <FontAwesomeIcon icon={Icons.faUser} />
                                      </label>
                                    )}
                                    <label className="label">
                                      Last login: &nbsp;
                                      <Moment date={session.lastLoggedIn} format="ddd DD. MMM YYYY HH:mm:ss" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer autoClose={2000} hideProgressBar={true} position="top-right" />
      </>
    );
  }
}
