import React from "react";
import {DragSource} from "react-dnd";
import {SOCKET_URI} from "../../constants/uri";
import {Types} from "../../constants/dndTypes";

const imgSize = {height: "100px", width: "100px"};

const tokenSource = {
  beginDrag(props) {
    const item = {id: props.id};
    // create custom window event
    const map = document.querySelector("#map");
    if (map) {
      map.dispatchEvent(new CustomEvent("startmount", {view: window, bubbles: true, cancelable: false}));
    }
    return item;
  },

  endDrag(props, monitor) {
    const item = monitor.getItem();
    const map = document.querySelector("#map");
    if (map) {
      map.dispatchEvent(new CustomEvent("endmount", {view: window, bubbles: true, cancelable: false}));
    }
    return item;
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
  };
}

function TokenImageDND(props) {
  const {connectDragSource} = props;

  return connectDragSource(
    <img
      style={imgSize}
      src={`${SOCKET_URI}/download/token/${props.token.tokenId ? props.token.tokenId : props.token.id}`}
      alt={props.token.tokenId ? props.token.tokenId : props.token.id}
      id={props.token.tokenId ? props.token.tokenId : props.token.id}
      key={`img-${props.token.tokenId ? props.token.tokenId : props.token.id}`}
      onClick={props.handleTokenClick || null}
    />
  );
}

export default DragSource(Types.TOKEN, tokenSource, collect)(TokenImageDND);
