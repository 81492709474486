import React from "react";
import autoBind from "react-autobind";

const sectionStyle = {
  backgroundColor: "darkgray",
  maxHeight: "100%",
  overflowY: "scroll",
  display: "flex",
  justifyContent: "center",
  paddingLeft: "36px",
  paddingRight: "36px",
};

const contentStyle = {
  maxWidth: "1024px",
  paddingBottom: "64px",
};

const cardStyle = {borderRadius: "8px", opacity: "0.9"};

export default class Documentation extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  render() {
    return (
      <>
        <section className="section bg-img-session" style={sectionStyle}>
          <div className="content" style={contentStyle}>
            <div className="card" style={cardStyle}>
              <div className="card-content">
                <h1>Documentation: Campaign Table Top (CTT)</h1>

                <div>
                  <p>Welcome to my documentation of the virtual table top game CTT!</p>

                  <p>
                    At first, you have to register on &nbsp;
                    <b>
                      <a
                        href="https://ctt.campaign-logger.cloud/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Link to CTT
                      </a>
                    </b>
                    &nbsp; to create your own Campaign ID Account for logging in. This process is quite
                    easy and should not raise any major difficulties. As soon as you have your own
                    Campaign ID, you can easily sign in and directly create a new game session or enter a
                    session ID to join an existing session.
                  </p>
                </div>

                <div className="doc-img">
                  <img src="static/img/login.png" alt="CTT Login" width="500" />
                </div>

                <div className="doc-img">
                  <img src="static/img/session.png" alt="CTT Session" width="500" />
                </div>

                <p>
                  From here, the documentation continues from the point of view of a game master (button:
                  create a new session). When the website has loaded, there are a lot of different
                  options that you can customize as a game master, but let’s give you a little overview
                  first.
                </p>

                <p>
                  On the left side is a user list with all the players who joined your actual session.
                  Below, you can find the group chat with the time and name of all the messages that are
                  written into the chat.
                </p>

                <div className="doc-img">
                  <img src="static/img/list und chat.png" alt="Boards" width="500" />
                </div>

                <p>
                  Above is a line that includes some sources, disclaimer and privacy policies for the
                  gamers so that they can inform about the background of the game.{" "}
                </p>

                <div className="doc-img">
                  <img src="static/img/pp.png" alt="PP" width="500" />
                </div>

                <p>
                  On the right side you can find important buttons to leave the session, log out or
                  change your profile settings, but they are not really important while playing the game.{" "}
                </p>

                <div className="doc-img">
                  <img src="static/img/important buttons.png" alt="Important Buttons" width="500" />
                </div>

                <div className="doc-img">
                  <img src="static/img/boards.png" alt="Boards" width="500" />
                </div>

                <p>
                  Below them, the game master menu is located with many different configuration options:
                  First, you can choose which board you want to play. One board is available from the
                  beginning, but you can easily upload any board you want to CTT to have your desired
                  game experience. Of course, it is possible to upload several boards, but only show one
                  map to the players to prepare the other maps for playing afterwards.
                </p>

                <div className="doc-img">
                  <img src="static/img/grid.png" alt="Grid" width="500" />
                </div>

                <p>
                  The next section of the game master menu is the grid. Here, you can select your grid
                  type, change your grid & token size and your grid thickness and color. Moreover, there
                  is the possibility to adjust the grid offset below. As you can see, everything is made
                  up to provide the best individual gaming experience.{" "}
                </p>

                <div className="doc-img">
                  <img src="static/img/tokens.png" alt="Tokens" width="500" />
                </div>

                <p>
                  The third section of the menu are the Tokens. They are used to give every player an
                  individual character which they can move over the board. Like before, one token is
                  available from the beginning, but you can upload as many tokens as you want
                  additionally. Furthermore, you can give them names to organize them and assign them to
                  the different players.
                </p>

                <div className="doc-img">
                  <img src="static/img/fow.png" alt="FOW" width="500" />
                </div>

                <div className="doc-img">
                  <img src="static/img/map.png" alt="map" width="500" />
                </div>

                <p>
                  The last part of the game master menu is the FOW (Fog of War). As soon as you enable
                  it, the map will be full of fog for all the players and they can neither see anything
                  nor move their characters on the map. But you -as the game master- have got the ability
                  to enable a certain space for the players which they can see and in which they can move
                  on.
                </p>

                <p>Now, there is nothing left to say, apart from: Have fun and start to play!</p>
                <br />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
