import {toast} from "react-toastify";
import {SOCKET_URI} from "../constants/uri";

export default class Upload {
  static upload(event, type, sessionId, userId) {
    return new Promise((resolve, reject) => {
      console.log(
        `Uploading ${event.target.files.length} file(s) with type: ${type} in session: ${sessionId}`
      );

      const files = event.target.files || null;

      if (files) {
        const data = new FormData();
        let names = [];

        for (const file of files) {
          data.append(type, file);
          names.push(file.name);
        }

        var url = new URL(`${SOCKET_URI}/upload/${type}`),
          params = {
            sessionId: sessionId,
            type: type,
            userId: userId,
            names: names,
          };
        Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

        const options = {
          method: "POST",
          body: data,
          credentials: "include",
        };

        fetch(url, options)
          .then((res) => {
            toast.success("Upload successful");
            resolve();
          })
          .catch((err) => {
            toast.error(`Upload failed: ${err.message}`);
            reject();
          });
      }
    });
  }
}
