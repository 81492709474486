import React from "react";
import Spinner from "react-spinner-material";

const spinnerStyle = {width: "100px", height: "100px", margin: "16px"};

export default class LoadingBoardThumbnail extends React.Component {
  render() {
    return (
      <div style={spinnerStyle}>
        <Spinner radius={90} color="#00f" stroke={10} visible={true} />
      </div>
    );
  }
}
