const {hostname} = window.location;

export const SOCKET_URI = (() => {
  switch (hostname) {
    case "ctt-client.azurewebsites.net":
      console.log("SOCKET_URI:", "https://ctt-service.azurewebsites.net");
      return "https://ctt-service.azurewebsites.net";
    case "ctt.campaign-logger.cloud":
      console.log("SOCKET_URI:", "https://ctt.api.campaign-logger.cloud");
      return "https://ctt.api.campaign-logger.cloud";
    default:
      console.log("SOCKET_URI:", "https://localhost:5001");
      return "https://localhost:5001";
  }
})();

export const DICE_SERVICE_URI = "https://dice.campaign-logger.com/dice-room";
