import React from "react";
import _ from "lodash";
import {Rect, Text, Circle} from "react-konva";
import eye from "../../static/images/eye.png";
import autoBind from "react-autobind";

export default class BoardThumbnail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      board: this.props.board,
      img: null,
      eye: null,
    };
    autoBind(this);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      this.setState((prev) => ({board: this.props.board}), this.loadImage());
    }
  }

  componentDidMount() {
    this.loadImage();
    this.loadEye();
  }

  loadImage() {
    let img = new Image();
    img.src = this.props.board.thumbnail;
    img.onload = () => {
      this.setState((prev) => ({
        ...prev,
        img: img,
      }));
    };
  }

  loadEye() {
    let img = new Image();
    img.src = eye;
    img.onload = () => {
      this.setState((prev) => ({
        ...prev,
        eye: img,
      }));
    };
  }

  manageBoard() {
    this.props.manageBoard(this.props.board.boardId);
  }

  selectBoard() {
    this.props.selectBoard(this.props.board.boardId);
  }

  deleteBoard() {
    this.props.deleteBoard(this.props.board.boardId);
  }

  render() {
    return (
      <>
        {this.props.managed && (
          <Rect
            key={`${this.props.i}-z`}
            x={this.props.x - 10}
            y={this.props.y - 10}
            width={290}
            height={125}
            fill="#000"
            opacity={0.2}
            cornerRadius={4}
          />
        )}
        {this.props.selected && (
          <Circle
            key={`${this.props.i}-y`}
            x={this.props.x + 5}
            y={this.props.y + 50}
            radius={20}
            fillPatternOffsetX={320}
            fillPatternOffsetY={320}
            draggable={false}
            fillPatternImage={this.state.eye}
            fillPatternScaleY={0.06}
            fillPatternScaleX={0.06}
          />
        )}
        <Rect
          id={this.props.board.boardId}
          key={`${this.props.i}-x`}
          x={this.props.x + 25}
          y={this.props.y}
          cornerRadius={4}
          width={150}
          height={100}
          stroke="black"
          strokeWidth={2}
          offsetX={-2}
          offsetY={-2}
          fillPatternImage={this.state.img}
          fillPatternScaleY={1}
          fillPatternScaleX={1}
          onClick={this.manageBoard}
        />
        <Text
          key={`${this.props.i}-id`}
          x={this.props.x + 60}
          y={this.props.y + 35}
          width={90}
          text={this.props.i + 1}
          fontSize={40}
          fill="black"
          align="center"
          onClick={this.manageBoard}
        />
        {!this.props.selected && (
          <Text
            key={`${this.props.i}-w`}
            x={this.props.x + 180}
            y={this.props.y + 5}
            width={90}
            text="Show this map to players"
            fontSize={16}
            fill="blue"
            align="center"
            onClick={this.selectBoard}
          />
        )}
        {!this.props.selected && !this.props.managed && (
          <Text
            key={`${this.props.i}-v`}
            x={this.props.x + 185}
            y={this.props.y + 85}
            text="Delete map"
            fontSize={16}
            fill="red"
            align="center"
            onClick={() => {
              if (window.confirm("Do you really want to delete this board?")) this.deleteBoard();
            }}
          />
        )}
      </>
    );
  }
}
