export default function drawRectGrid(ctx, bw, gridSize, ox, oy, bh, lc, lw) {
  for (let x = 0; x <= bw; x += gridSize) {
    ctx.moveTo(0.5 + x + ox, 0);
    ctx.lineTo(0.5 + x + ox, bh);
  }

  for (let y = 0; y <= bh; y += gridSize) {
    ctx.moveTo(0, 0.5 + y + oy);
    ctx.lineTo(bw, 0.5 + y + oy);
  }
  ctx.strokeStyle = lc;
  ctx.lineWidth = lw;
  ctx.stroke();
}
