// This component takes the gamedata and converts it to this client and vice versa, to render it properly in the Board component

import "react-toastify/dist/ReactToastify.css";
import "react-chat-elements/dist/main.css";
import * as Icons from "@fortawesome/free-solid-svg-icons";

import AdminMenu from "../components/UI/AdminMenu";
import AvatarBrowser from "./../components/UI/AvatarBrowser";
import Board from "../components/Board";
import ChatBox from "../components/UI/ChatBox";
import NavBar from "../components/UI/NavBar";
import React from "react";
import {ToastContainer} from "react-toastify";
import TokenManager from "./../components/UI/TokenManager/TokenManager";
import UserList from "../components/UI/UserList";
import _ from "lodash";
import autoBind from "react-autobind";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Container = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

const quickViewStyle = {top: "52px", width: "30%", minWidth: "500px"};
const fullHeight = {height: "100%"};
const quickViewActive = {top: "52px", width: "56px", opacity: "0.75"};

const tooltipStyle = {
  position: "fixed",
  bottom: "0px",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  opacity: "0.8",
  zIndex: "10",
  padding: "12px",
  borderRadius: "4px",
  fontSize: "20px",
  color: "black",
  maxWidth: "50%",
};

export default class Game extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userChatData: "",
      sessionData: {},
      error: true,
      errorMessage: "",
      userId: this.props.userId || null,
      userName: this.props.userName || null,
      isGameMaster: this.props.isGameMaster || false,
      selectedTokens: [],
      hlToken: null,
      showTokenManager: false,
      showAvatarBrowser: false,
      fowMode: false,
      fowDraw: "free",
      fowEnabled: false,
      showAdminMenu: false,
      showMarkerMenu: false,
      addNewMarker: false,
      viewToolTip: "",
      showUserList: true,
      showChatWindow: true,
    };

    autoBind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      this.setState((prev) => ({
        sessionData: this.props.sessionData,
        userId: this.props.userId,
        userName: this.props.userName,
        isGameMaster: this.props.isGameMaster,
        fowEnabled:
          this.props.sessionData.boards &&
          this.props.sessionData.boards.find((b) => b.boardId === this.props.sessionData.manageId)
            ? this.props.sessionData.boards.find((b) => b.boardId === this.props.sessionData.manageId).fowEnabled
            : false,
      }));
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  setFowMode(value) {
    this.setState((prev) => ({
      ...prev,
      fowMode: value,
    }));
  }

  setMarkerMenu(value) {
    this.setState((prev) => ({
      ...prev,
      showMarkerMenu: value,
    }));
  }

  setMarkerAdding(value) {
    this.setState((prev) => ({
      ...prev,
      addNewMarker: value,
    }));
  }

  handleTokenDragEnd(e) {
    const token = {
      id: e.target.attrs.id,
      positionX: Math.round(e.target.attrs.x),
      positionY: Math.round(e.target.attrs.y),
      visible: true,
    };

    // board boundary limits
    if (token.positionX <= 0) token.positionX = 1;
    if (token.positionY <= 0) token.positionY = 1;
    if (token.positionX > this.state.sessionData.boardWidth) token.positionX = this.state.sessionData.boardWidth - 1;
    if (token.positionY > this.state.sessionData.boardHeight) token.positionY = this.state.sessionData.boardHeight - 1;

    this.props.sendTokenMove(token);
  }

  handleTokenClick(e) {
    let tokenId;

    if (e.target.attrs && e.target.attrs.id) {
      tokenId = e.target.attrs.id;

      if (e.evt.ctrlKey) {
        // multi select mode
        if (this.state.selectedTokens.includes(tokenId)) {
          const newSelectedTokens = this.state.selectedTokens.filter((v) => v !== tokenId);
          this.setState((prev) => ({
            ...prev,
            selectedTokens: newSelectedTokens,
          }));
        } else {
          let newSelectedTokens = this.state.selectedTokens;
          newSelectedTokens.push(tokenId);
          this.setState((prev) => ({
            ...prev,
            selectedTokens: newSelectedTokens,
          }));
        }
      } else {
        // single select mode
        if (this.state.selectedTokens.includes(tokenId)) {
          this.setState((prev) => ({
            ...prev,
            selectedTokens: [],
          }));
        } else {
          let newSelectedTokens = [];
          newSelectedTokens.push(tokenId);
          this.setState((prev) => ({
            ...prev,
            selectedTokens: newSelectedTokens,
          }));
        }
      }
    } else {
      tokenId = parseInt(e.target.id);
      if (e.ctrlKey) {
        // multi select mode
        if (this.state.selectedTokens.includes(tokenId)) {
          const newSelectedTokens = this.state.selectedTokens.filter((v) => v !== tokenId);
          this.setState((prev) => ({
            ...prev,
            selectedTokens: newSelectedTokens,
          }));
        } else {
          let newSelectedTokens = this.state.selectedTokens;
          newSelectedTokens.push(tokenId);
          this.setState((prev) => ({
            ...prev,
            selectedTokens: newSelectedTokens,
          }));
        }
      } else {
        // single select mode
        if (this.state.selectedTokens.includes(tokenId)) {
          this.setState((prev) => ({
            ...prev,
            selectedTokens: [],
          }));
        } else {
          let newSelectedTokens = [];
          newSelectedTokens.push(tokenId);
          this.setState((prev) => ({
            ...prev,
            selectedTokens: newSelectedTokens,
          }));
        }
      }
    }
  }

  handleBoardClick(e) {
    if (e.target.id === "markers" && this.state.addNewMarker) {
      this.setMarkerAdding(false);
      const marker = {
        positionX: e.offsetX,
        positionY: e.offsetY,
      };
      this.props.addMarker(marker, this.state.sessionData.manageId);
    }
    const target = e.target.tagName.toLowerCase();
    if (target === "canvas" && e.target.clientWidth > 1000 && this.state.hlTokens !== null) {
      // make token visible and position it to cursor pos
      let token = this.state.sessionData.mountedTokens.filter((t) => t.id === this.state.hlToken)[0];
      if (!token) return;
      token.positionX = e.offsetX;
      token.positionY = e.offsetY;
      token.visible = true;
      this.props.sendTokenMove(token);
      this.setState((prev) => ({
        ...prev,
        hlToken: [],
        selectedTokens: [],
      }));
    }
  }

  handleResize() {
    this.setState((prev) => ({
      ...prev,
      gameWidth: window.innerWidth,
      gameHeight: window.innerHeight,
    }));
  }

  handleShowAvatarBrowser() {
    this.setState((prev) => ({showAvatarBrowser: true}));
  }

  changeGrid(grid) {
    this.setState((prev) => ({
      ...prev,
      selectedTokens: [],
    }));
    this.props.sendGridChange(
      grid,
      this.state.sessionData.gridSize,
      this.state.sessionData.gridLineWidth,
      this.state.sessionData.gridLineColor,
      this.state.sessionData.gridOffsetX,
      this.state.sessionData.gridOffsetY
    );
  }

  incrementGridSize() {
    let gridSize = this.state.sessionData.gridSize;
    if (gridSize <= 500) {
      gridSize += 1;
      this.props.sendGridChange(
        this.state.sessionData.grid,
        gridSize,
        this.state.sessionData.gridLineWidth,
        this.state.sessionData.gridLineColor,
        this.state.sessionData.gridOffsetX,
        this.state.sessionData.gridOffsetY
      );
    }
  }

  decrementGridSize() {
    let gridSize = this.state.sessionData.gridSize;
    if (gridSize >= 10) {
      gridSize -= 1;
      this.props.sendGridChange(
        this.state.sessionData.grid,
        gridSize,
        this.state.sessionData.gridLineWidth,
        this.state.sessionData.gridLineColor,
        this.state.sessionData.gridOffsetX,
        this.state.sessionData.gridOffsetY
      );
    }
  }

  setGridSize(gridSize) {
    if (gridSize >= 10 && gridSize <= 500) {
      this.props.sendGridChange(
        this.state.sessionData.grid,
        gridSize,
        this.state.sessionData.gridLineWidth,
        this.state.sessionData.gridLineColor,
        this.state.sessionData.gridOffsetX,
        this.state.sessionData.gridOffsetY
      );
    }
  }

  setGridWidth(width) {
    this.props.sendGridChange(
      this.state.sessionData.grid,
      this.state.sessionData.gridSize,
      width,
      this.state.sessionData.gridLineColor,
      this.state.sessionData.gridOffsetX,
      this.state.sessionData.gridOffsetY
    );
  }

  setGridLineColor(color) {
    this.props.sendGridChange(
      this.state.sessionData.grid,
      this.state.sessionData.gridSize,
      this.state.sessionData.gridLineWidth,
      color,
      this.state.sessionData.gridOffsetX,
      this.state.sessionData.gridOffsetY
    );
  }

  setGridOffset(x, y) {
    const ox = !x ? 0 : x;
    const oy = !y ? 0 : y;

    this.props.sendGridChange(
      this.state.sessionData.grid,
      this.state.sessionData.gridSize,
      this.state.sessionData.gridLineWidth,
      this.state.sessionData.gridLineColor,
      ox,
      oy
    );
  }

  previewGridOffset(x, y) {
    this.setState((prev) => ({
      ...prev,
      sessionData: {
        ...this.state.sessionData,
        gridOffsetX: x,
        gridOffsetY: y,
      },
    }));
  }

  selectBoard(boardId) {
    this.setState((prev) => ({
      ...prev,
      selectedTokens: [],
    }));
    this.props.selectBoard(boardId);
  }

  manageBoard(manageId) {
    this.setState((prev) => ({
      ...prev,
      selectedTokens: [],
    }));
    this.props.manageBoard(manageId);
  }

  deleteBoard(boardId) {
    this.setState((prev) => ({
      ...prev,
      selectedTokens: [],
    }));
    this.props.deleteBoard(boardId);
  }

  handleDeleteToken(index) {
    this.props.sendTokenRemove(index);
    this.setState((prev) => ({
      ...prev,
      selectedTokens: [],
    }));
  }

  handleCollectToken(index) {
    this.props.sendTokenCollect(index);
    this.setState((prev) => ({
      ...prev,
      selectedTokens: [],
    }));
  }

  handleOpenTokenManager() {
    this.setState((prev) => ({
      ...prev,
      showTokenManager: true,
    }));
  }

  handleCloseTokenManager() {
    this.setState((prev) => ({
      ...prev,
      showTokenManager: false,
    }));
  }

  handleOpenAdminMenu() {
    this.setState((prev) => ({
      ...prev,
      showAdminMenu: true,
    }));
  }

  handleCloseAdminMenu() {
    this.setState((prev) => ({
      ...prev,
      showAdminMenu: false,
    }));
  }

  handleChangeCategory(id, cat) {
    this.props.sendChangeCategory(id, cat);
  }

  handleChangeCaption(id, cap) {
    this.props.sendChangeCaption(id, cap);
  }

  handleCopyTokens(targetBoard) {
    this.props.handleCopyTokens(targetBoard, this.state.selectedTokens);
  }

  handleTokenMultiChange(id, multi) {
    this.props.handleTokenMultiChange(id, multi);
  }

  handleOpenAvatarBrowser() {
    this.setState((prev) => ({
      ...prev,
      showAvatarBrowser: true,
    }));
  }

  handleCloseAvatarBrowser() {
    this.setState((prev) => ({
      ...prev,
      showAvatarBrowser: false,
    }));
  }

  handleDeleteAvatar(id) {
    this.props.handleRemoveAvatar(id, this.state.userId, this.state.userName);
  }

  changeFowDrawMode(mode) {
    this.setState((prev) => ({
      ...prev,
      fowDraw: mode,
    }));
  }

  deleteFow() {
    this.props.deleteFow(this.state.sessionData.manageId);
  }

  deleteAllPolygons() {
    this.props.deleteAllPolygons(this.state.sessionData.manageId);
  }

  deleteLastPolygon() {
    this.props.deleteLastPolygon(this.state.sessionData.manageId);
  }

  handleFowDrawing(polygon) {
    if (!this.state.fowEnabled) return;
    if (this.state.fowDraw === "free") {
      this.props.freePolygon(this.state.sessionData.manageId, polygon);
    } else {
      this.props.fogPolygon(this.state.sessionData.manageId, polygon);
    }
  }

  setFow(enabled) {
    this.props.setFow(enabled, this.state.sessionData.manageId);
  }

  storeMarker(marker) {
    this.props.storeMarker(marker);
  }

  deleteMarker(marker) {
    this.props.deleteMarker(marker);
  }

  showDescription(id) {
    const tooltipText = this.state.sessionData.markers.find((m) => m.id === id).desc;
    if (this.state.viewToolTip !== tooltipText) {
      this.setState((prev) => ({
        ...prev,
        viewToolTip: tooltipText,
      }));
    }
  }

  hideDescription() {
    if (this.state.viewToolTip !== "") {
      this.setState((prev) => ({
        ...prev,
        viewToolTip: "",
      }));
    }
  }

  handleToggleUserListVisibility() {
    this.setState((prev) => ({
      ...prev,
      showUserList: !this.state.showUserList,
    }));
  }

  handleToggleChatWindowVisibility() {
    this.setState((prev) => ({
      ...prev,
      showChatWindow: !this.state.showChatWindow,
    }));
  }

  render() {
    return (
      <Container>
        <div className="div">
          <NavBar
            userName={this.state.userName}
            userId={this.state.userId}
            boardId={this.state.sessionData.boardId}
            sessionId={this.state.sessionData.sessionId}
            isGameMaster={this.state.isGameMaster}
            onDisconnectHandler={this.props.onDisconnectHandler}
            handleOpenAvatarBrowser={this.handleOpenAvatarBrowser}
            loggedIn
            handleToggleUserListVisibility={this.handleToggleUserListVisibility}
            handleToggleChatWindowVisibility={this.handleToggleChatWindowVisibility}
            showUserList={this.state.showUserList}
            showChatWindow={this.state.showChatWindow}
          />

          {this.state.showUserList && (
            <UserList
              session={this.props.sessionData}
              userId={this.state.userId}
              isGameMaster={this.state.isGameMaster}
              handleToggleUserListVisibility={this.handleToggleUserListVisibility}
            />
          )}

          {this.state.showChatWindow && (
            <ChatBox
              chatData={this.props.sessionData.groupChat}
              sendChatMessage={this.props.sendChatMessage}
              handleToggleChatWindowVisibility={this.handleToggleChatWindowVisibility}
            />
          )}

          {this.state.isGameMaster && this.state.showAdminMenu && (
            <div id="quickviewDefault" className="quickview is-active" style={quickViewStyle}>
              <header className="quickview-header">
                <p className="title">Game Master Menu</p>
                <span className="delete" data-dismiss="quickview" onClick={this.handleCloseAdminMenu}></span>
              </header>

              <div className="quickview-body">
                <AdminMenu
                  handleDeleteToken={this.handleDeleteToken}
                  handleCollectToken={this.handleCollectToken}
                  handleFileUpload={this.props.handleFileUpload}
                  handleTokenClick={this.handleTokenClick}
                  handleOpenTokenManager={this.handleOpenTokenManager}
                  handleCopyTokens={this.handleCopyTokens}
                  handleTokenMultiChange={this.handleTokenMultiChange}
                  setGridWidth={this.setGridWidth}
                  setGridSize={this.setGridSize}
                  incrementGridSize={this.incrementGridSize}
                  decrementGridSize={this.decrementGridSize}
                  setGridLineColor={this.setGridLineColor}
                  setGridOffset={this.setGridOffset}
                  previewGridOffset={this.previewGridOffset}
                  sessionId={this.state.sessionData.sessionId}
                  userId={this.state.userId}
                  selectedTokens={this.state.selectedTokens}
                  hlToken={this.state.hlToken}
                  mountedTokens={this.state.sessionData.mountedTokens || []}
                  allTokens={this.state.sessionData.tokens || []}
                  handleChangeCaption={this.handleChangeCaption}
                  changeGrid={this.changeGrid}
                  grid={this.state.sessionData.grid}
                  gridSize={this.state.sessionData.gridSize}
                  gridLineWidth={this.state.sessionData.gridLineWidth}
                  gridLineColor={this.state.sessionData.gridLineColor}
                  gridOffsetX={this.state.sessionData.gridOffsetX}
                  gridOffsetY={this.state.sessionData.gridOffsetY}
                  boards={this.state.sessionData.boards}
                  boardId={this.state.sessionData.boardId}
                  manageId={this.state.sessionData.manageId}
                  selectBoard={this.selectBoard}
                  manageBoard={this.manageBoard}
                  deleteBoard={this.deleteBoard}
                  setFowMode={this.setFowMode}
                  changeFowDrawMode={this.changeFowDrawMode}
                  setFow={this.setFow}
                  deleteFow={this.deleteFow}
                  deleteAllPolygons={this.deleteAllPolygons}
                  deleteLastPolygon={this.deleteLastPolygon}
                  fowEnabled={this.state.sessionData.fowEnabled}
                  uploading={this.props.uploading}
                  handleImageError={this.props.handleImageError}
                  fowMode={this.state.fowDraw}
                  showMarkerMenu={this.state.showMarkerMenu}
                  setMarkerMenu={this.setMarkerMenu}
                  addNewMarker={this.state.addNewMarker}
                  setMarkerAdding={this.setMarkerAdding}
                  gameData={this.state.sessionData}
                  storeMarker={this.storeMarker}
                  deleteMarker={this.deleteMarker}
                />
              </div>
            </div>
          )}
          {this.state.isGameMaster && !this.state.showAdminMenu && (
            <div id="quickviewDefault" className="quickview is-active" style={quickViewActive}>
              <button className="button is-info" onClick={this.handleOpenAdminMenu} style={fullHeight}>
                <FontAwesomeIcon icon={Icons.faArrowLeft} size="3x" color="black" />
              </button>
            </div>
          )}
        </div>

        <Board
          gameData={this.state.sessionData}
          handleTokenDragEnd={this.handleTokenDragEnd}
          handleTokenClick={this.handleTokenClick}
          handleBoardClick={this.handleBoardClick}
          isGameMaster={this.state.isGameMaster}
          selectedTokens={this.state.selectedTokens}
          fowMode={this.state.fowMode}
          drawFow={this.handleFowDrawing}
          userId={this.state.userId}
          sessionId={this.state.sessionData.sessionId}
          fowMask={this.state.sessionData.fowMask}
          fowEnabled={this.state.sessionData.fowEnabled}
          reLogin={this.props.reLogin}
          handleImageError={this.props.handleImageError}
          handleMountToken={this.props.handleMountToken}
          showMarkerMenu={this.state.showMarkerMenu}
          addNewMarker={this.state.addNewMarker}
          showDescription={this.showDescription}
          hideDescription={this.hideDescription}
          moveMarker={this.props.moveMarker}
        />

        {this.state.showTokenManager && (
          <TokenManager
            handleCloseTokenManager={this.handleCloseTokenManager}
            handleFileUpload={this.props.handleFileUpload}
            handleDeleteToken={this.handleDeleteToken}
            users={this.state.sessionData.users}
            userId={this.state.userId}
            boardId={this.state.sessionData.boardId}
            manageId={this.state.sessionData.manageId}
            sessionId={this.state.sessionData.sessionId}
            tokens={this.state.sessionData.tokens}
            changeCategory={this.handleChangeCategory}
            changeCaption={this.handleChangeCaption}
            boards={this.state.sessionData.boards}
            setTags={this.props.setTags}
            uploading={this.props.uploading}
          />
        )}

        {this.state.showAvatarBrowser && (
          <AvatarBrowser
            handleCloseAvatarBrowser={this.handleCloseAvatarBrowser}
            handleFileUpload={this.props.handleFileUpload}
            handleDeleteAvatar={this.handleDeleteAvatar}
            userId={this.state.userId}
            boardId={this.state.sessionData.boardId}
            sessionId={this.state.sessionData.sessionId}
            user={this.state.sessionData.users.find((u) => u.userId === this.state.userId)}
            handleImageError={this.props.handleImageError}
          />
        )}
        {this.state.viewToolTip && <div style={tooltipStyle}>{this.state.viewToolTip}</div>}
        <ToastContainer autoClose={2000} hideProgressBar={true} position="top-right" />
      </Container>
    );
  }
}
