/* eslint-disable jsx-a11y/anchor-is-valid */
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import React from "react";
import {Link} from "react-router-dom";
import {useEffect} from "react";
import {useState} from "react";

const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export default function Navbar(props) {
  const [isActive, setisActive] = React.useState(false);
  const [width, setWidth] = useState(getWidth());

  useEffect(() => {
    let timeoutId = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      setisActive(false);
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  function disconnectHandlerTrue() {
    if (props) props.onDisconnectHandler(true);
  }

  function disconnectHandlerFalse() {
    if (props) props.onDisconnectHandler(false);
  }

  function toggleUserList() {
    if (props) props.handleToggleUserListVisibility();
  }

  function toggleChatWindow() {
    if (props) props.handleToggleChatWindowVisibility();
  }

  function openProfileSettings() {
    window.open("https://campaign-identity.com/", "_blank");
  }

  const navbarStyle = {
    opacity: 0.9,
    paddingTop: "0px",
    paddingBottom: "0px",
    zIndex: 50,
    position: "absolute",
    width: width,
    height: "52px",
  };

  return (
    <nav className="navbar is-grey" role="navigation" aria-label="main navigation" style={navbarStyle}>
      <div className={isActive ? "navbar-brand color-info" : "navbar-brand"}>
        {props.loggedIn ? (
          <div className="navbar-item">
            <b>Campaign Table Top</b>
            &nbsp;
            {!props.isGameMaster && (
              <span className="icon">
                <FontAwesomeIcon icon={Icons.faUser} />
              </span>
            )}
            {props.isGameMaster && (
              <span className="icon">
                <FontAwesomeIcon icon={Icons.faCrown} />
              </span>
            )}
          </div>
        ) : (
          <a className="navbar-item" href="https://ctt.campaign-logger.cloud​/">
            <span>
              <b>Campaign Table Top</b>
            </span>
          </a>
        )}

        <a
          onClick={() => {
            setisActive(!isActive);
          }}
          role="button"
          className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div id="navbarBasicExample" className={`navbar-menu ${isActive ? "is-active" : ""}`}>
        <div className={props.loggedIn || props.sessionPage ? "navbar-end" : "navbar-start"}>
          {/* Normal Navbar START */}
          {!isActive && (
            <>
              {props.loggedIn && (
                <>
                  <div
                    className="navbar-item cursor-pointer"
                    onClick={toggleUserList}
                    title={props.showUserList ? "Hide user list" : "Show user list"}
                    style={
                      props.showUserList
                        ? {backgroundColor: "#3298dc", margin: "6px", borderRadius: "4px"}
                        : {margin: "6px"}
                    }
                  >
                    <span className="icon">
                      <FontAwesomeIcon icon={Icons.faUsers} size="lg" color={props.showUserList ? "white" : ""} />
                    </span>
                  </div>
                  <div
                    className="navbar-item cursor-pointer"
                    onClick={toggleChatWindow}
                    title={props.showChatWindow ? "Hide chat window" : "Show chat window"}
                    style={
                      props.showChatWindow
                        ? {backgroundColor: "#3298dc", margin: "6px", borderRadius: "4px"}
                        : {margin: "6px"}
                    }
                  >
                    <span className="icon">
                      <FontAwesomeIcon
                        icon={Icons.faCommentAlt}
                        size="lg"
                        color={props.showChatWindow ? "white" : ""}
                      />
                    </span>
                  </div>
                </>
              )}

              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link">Menu</a>

                <div className="navbar-dropdown">
                  <Link className="navbar-item" to="/sources" target="blank">
                    Image Sources & Licenses
                  </Link>

                  <a className="navbar-item" href="https://campaign-logger.com/#/disclaimer" target="blank">
                    Disclaimer
                  </a>
                  <a className="navbar-item" href="https://campaign-logger.com/#/privacy" target="blank">
                    Our Cookie & Privacy Policy
                  </a>
                  <a className="navbar-item" href="https://campaign-logger.com/#/lnotice" target="blank">
                    Legal Notice & Imprint
                  </a>

                  <Link className="navbar-item" to="/beginners-guide" target="blank">
                    Beginner's Guide
                  </Link>
                  {props.loggedIn && (
                    <>
                      <hr className="navbar-divider" />

                      {!props.isGameMaster && (
                        <div
                          className="navbar-item navbar-menu-item cursor-pointer"
                          onClick={props.handleOpenAvatarBrowser}
                        >
                          <span className="icon">
                            <FontAwesomeIcon icon={Icons.faUpload} />
                          </span>
                          <span>Change avatar</span>
                        </div>
                      )}
                      <div className="navbar-item navbar-menu-item cursor-pointer" onClick={openProfileSettings}>
                        Change Profile Settings
                      </div>
                    </>
                  )}
                </div>
              </div>
              {props.loggedIn && (
                <>
                  {!props.isGameMaster && (
                    <div className="navbar-item">
                      <div className="field is-grouped">
                        <span>Role: Player</span>
                        &nbsp;
                        <span className="icon">
                          <FontAwesomeIcon icon={Icons.faUser} />
                        </span>
                      </div>
                    </div>
                  )}

                  {props.isGameMaster && (
                    <div className="navbar-item">
                      <div className="field is-grouped">
                        <span>Role: Game Master</span>
                        &nbsp;
                        <span className="icon">
                          <FontAwesomeIcon icon={Icons.faCrown} />
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="navbar-item">
                    <div className="buttons">
                      <div className="button is-warning" onClick={disconnectHandlerFalse}>
                        Leave Session
                      </div>
                    </div>
                  </div>
                </>
              )}
              {(props.loggedIn || props.sessionPage) && (
                <div className="navbar-item">
                  <div className="buttons">
                    <div className="button is-danger" onClick={disconnectHandlerTrue}>
                      Log out:&nbsp;
                      <span className="signed-in-user">{props.userName || ""}</span>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {/* Normal Navbar END */}

          {/* Mobile Navbar START */}
          {isActive && (
            <>
              <Link className="navbar-item" to="/sources" target="blank">
                Image Sources & Licenses
              </Link>

              <a className="navbar-item" href="https://campaign-logger.com/#/disclaimer" target="blank">
                Disclaimer
              </a>
              <a className="navbar-item" href="https://campaign-logger.com/#/privacy" target="blank">
                Our Cookie & Privacy Policy
              </a>
              <a className="navbar-item" href="https://campaign-logger.com/#/lnotice" target="blank">
                Legal Notice & Imprint
              </a>

              <Link className="navbar-item" to="/beginners-guide" target="blank">
                Beginner's Guide
              </Link>
              <hr className="navbar-divider" />

              {!props.isGameMaster && (
                <div className="navbar-item cursor-pointer" onClick={props.handleOpenAvatarBrowser}>
                  <span className="icon">
                    <FontAwesomeIcon icon={Icons.faUpload} />
                  </span>
                  <span>Change avatar</span>
                </div>
              )}

              <div className="navbar-item cursor-pointer burger-menu-item" onClick={toggleUserList}>
                {props.showUserList ? "Hide user list" : "Show user list"}
              </div>

              <div className="navbar-item cursor-pointer burger-menu-item" onClick={toggleChatWindow}>
                {props.showChatWindow ? "Hide chat window" : "Show chat window"}
              </div>

              <div className="navbar-item cursor-pointer burger-menu-item" onClick={openProfileSettings}>
                Change Profile Settings
              </div>

              <div className="navbar-item cursor-pointer burger-menu-item" onClick={disconnectHandlerFalse}>
                Leave Session
              </div>

              <div className="navbar-item cursor-pointer burger-menu-item" onClick={disconnectHandlerTrue}>
                Log out:&nbsp;
                <span className="signed-in-user">{props.userName || ""}</span>
              </div>
            </>
          )}

          {/* Mobile Navbar END */}
        </div>
      </div>
    </nav>
  );
}
