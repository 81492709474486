import React from "react";
import {Circle, Group, Label, Tag, Text} from "react-konva";
import _ from "lodash";
import autoBind from "react-autobind";

import pickerImage from "../../static/images/picker.png";

export default class Marker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      marker: this.props.marker,
      img: null,
    };
    autoBind(this);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      this.setState((prev) => ({...prev, marker: this.props.marker}));
    }
  }

  componentDidMount() {
    this.loadImage();
  }

  loadImage() {
    let img = new Image();
    img.src = pickerImage;
    img.onload = () => {
      this.setState((prev) => ({
        ...prev,
        img: img,
      }));
    };
  }

  handleMarkerMoved(e) {
    const newMarker = {
      ...this.state.marker,
      id: e.target.attrs.id,
      x: Math.round(e.target.attrs.x),
      y: Math.round(e.target.attrs.y),
    };
    // board boundary limits
    if (newMarker.x <= 0) newMarker.x = 1;
    if (newMarker.y <= 0) newMarker.y = 1;
    if (newMarker.x > this.props.boardWidth) newMarker.x = this.props.boardWidth - 1;
    if (newMarker.y > this.props.boardHeight) newMarker.y = this.props.boardHeight - 1;
    this.props.moveMarker(newMarker);
  }

  render() {
    return (
      <>
        {this.state.marker !== null && (
          <Group
            onMouseDown={this.props.handleMouseDown}
            onMouseUp={this.props.handleMouseUp}
            id={this.state.marker.id}
            draggable={this.props.draggable}
            onDragEnd={this.handleMarkerMoved}
            x={this.state.marker.x}
            y={this.state.marker.y}
          >
            <Circle
              radius={this.props.gridSize / 2 - 2}
              fillPatternImage={this.state.img}
              fillPatternScaleY={this.state.img ? this.props.gridSize / 250 : 1}
              fillPatternScaleX={this.state.img ? this.props.gridSize / 250 : 1}
              fillPatternOffsetX={this.state.img ? this.state.img.width / 2 : 0}
              fillPatternOffsetY={this.state.img ? this.state.img.height / 2 : 0}
            />
            <Label x={this.props.gridSize / 2.5} y={-this.props.gridSize / 4}>
              <Tag fill="white" opacity={0.7} cornerRadius={this.props.gridSize / 20} />
              <Text
                text={this.state.marker.title}
                fontSize={this.props.gridSize / 2}
                x={this.props.gridSize / 2}
                padding={this.props.gridSize / 20}
                offsetY={-this.props.gridSize / 20}
              />
            </Label>
          </Group>
        )}
      </>
    );
  }
}
